



































































































// Vue property decorator
import {
  Component,
  Prop,
  Emit,
  Mixins,
  Vue,
  Watch,
} from "vue-property-decorator";

import Navigator from "../navigator/index.vue";

// Event bus used to fire/capture events
import { EventBus } from "../../event-bus/index";
// Mixins
import ToolMixin from "../../mixins/accessors/tool";
import NftMixin from "../../mixins/accessors/nft";

import Axios from "../../middleware/api";

declare const Buffer: any;

@Component({
  components: { Navigator },
  filters: {
    rotateColor(payload: any) {
      const colors: Array<string> = [
        "primary",
        "red",
        "purple",
        "green",
        "amber",
      ];

      const getRandomInt = (max: number) => {
        return Math.floor(Math.random() * Math.floor(max));
      };

      return colors[getRandomInt(4)];
    },
    fragment(address: any) {
      const frag_1: string = address.substr(0, 5);
      const frag_2: string = address.slice(-4);

      return `${frag_1}...${frag_2}`;
    },
  },
})
export default class Sidebar extends Mixins(ToolMixin, NftMixin) {
  greeting: string = "";
  agencyImage: any = "";
  interval: any = {};
  time: number = 1000 * 60 * 30;
  isMobile: boolean = false;
  group?: any = null;

  get theme(): boolean {
    return this.$vuetify.theme.dark;
  }

  @Watch("isMobile")
  mobile() {
    if (!this.isMobile) this.sideNav = false;
  }

  beforeDestroy() {
    clearInterval(this.interval);
    if (typeof window === "undefined") return;

    const obj: any = {
      passive: true,
    };

    window.removeEventListener("resize", this.onResize, obj);
  }

  created() {
    this.currentTime();
  }

  mounted() {
    this.interval = setInterval(() => {
      this.currentTime();
    }, this.time);

    this.onResize();

    window.addEventListener("resize", this.onResize, { passive: true });
  }

  private currentTime(): void {
    const today = new Date();
    const curHr = today.getHours();

    if (curHr < 12) {
      this.greeting = `Good Morning, Name`;
    } else if (curHr < 18) {
      this.greeting = `Good Afternoon, Name`;
    } else {
      this.greeting = `Good Evening, Name`;
    }
  }

  private onResize() {
    this.isMobile = window.innerWidth < 960;
  }

  private unlockWallet(): void {
    EventBus.$emit("UNLOCK_WALLET");
  }

  private lockWallet(): void {
    this.$cookies.remove("wallet");
    this.unlocked = false;
    this.myCollection.splice(0, this.myCollection.length);
  }

  toggleTheme(): void {
    this.$cookies.remove("darkMode");
    this.$cookies.remove("lightMode");

    this.$vuetify.theme.dark = !this.$vuetify.theme.dark;

    if (this.$vuetify.theme.dark) {
      this.$cookies.set("darkMode", this.$vuetify.theme.dark);
      this.darkTheme = true;
    } else {
      this.$cookies.set("lightMode", this.$vuetify.theme.dark);
      this.darkTheme = false;
    }
  }
}
