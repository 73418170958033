import { Schema2 } from "../schemas/schema-2";

import helpers from "../../../helpers/helpers";

import BigNumber from "bignumber.js";

export class Yop extends Schema2 {
  constructor(
    assetPrice: string | number,
    name: string = "Featured Drop - YOP",
    address: string = "0x53798AAD04807e9795Dd0D719637B4051E304931",
    id: number = 0,
    assetType: string = "YOP"
  ) {
    super(assetPrice, name, address, id, assetType);
    this.initialize();
  }

  private async initialize(): Promise<any> {
    const abi = await super.getAbi();

    await super.getContract(abi);

    await this.supply();

    await super.getPastEvents();

    await super.getPineapples();
  }

  public async supply(): Promise<any> {
    const process = (res: any) => {
      if (res) {
        const decimals: number = 8;
        const total = helpers.applyDecimals(res, decimals);

        this._totalValue = total;

        super._totalLockedValue = new BigNumber(this._price)
          .multipliedBy(total)
          .toNumber();
      }
    };

    const promise = new Promise((res: any, rej: any) => {
      const request = async (): Promise<any> => {
        try {
          const _res = await this.contract.methods
            .balanceOfPool(this.id)
            .call();
          process(_res);

          return res("resolved");
        } catch (error) {
          if (error) return rej;
        }
      };
      return request();
    });
   
    return promise;
  }
}
