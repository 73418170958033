import Web3 from "web3";

import axios from "../../../middleware/api";
import infura from "../../../web3/web3";

import helpers from "../../../helpers/helpers";

import BigNumber from "bignumber.js";

const web3: Web3 = new Web3(infura);

export class Schema2 {
  _address: string;
  _assetType: string;
  contract?: any;
  _name: string;
  id: number;
  _pineapples?: string | number;
  _price: string | number;
  _rank?: string | number;
  subContracts: Array<any> = [];
  _totalLockedValue?: string | number;
  _totalValue?: string | number;
  _transactions: Array<any> = [];

  constructor(
    assetPrice: string | number,
    name: string,
    address: string,
    id: number,
    assetType: string = "MEME"
  ) {
    this._address = address;
    this._assetType = assetType;
    this._name = name;
    this.id = id;
    this._price = assetPrice;
  }

  public async getAbi(): Promise<any> {
    const process = (response: any) => {
      const _abi = response.data.result;

      return _abi;
    };

    const promise = new Promise<any>((res: any, rej: any) => {
      const request = async (): Promise<any> => {
        try {
          const response = await axios.getAbi(this._address);

          const abi = process(response);
          return res(abi);
        } catch (error) {
          if (error) return rej;
        }
      };
      const contractAbi = request();
      return contractAbi;
    });

    return promise;
  }

  public async getContract(abi: any): Promise<any> {
      return new Promise((res: any, rej: any) => {
          try {
            this.contract = new web3.eth.Contract(JSON.parse(abi), this._address);
    
            res("resolved")
          } catch (error) {
              rej(error)
          }
      })
  }

  public async totalSupply(): Promise<any> {
    const process = (res: any) => {
      if (res) {
        const decimals: number = 8;
        const total = helpers.applyDecimals(res, decimals);

        this._totalValue = total;

        this._totalLockedValue = new BigNumber(this._price)
          .multipliedBy(total)
          .toNumber();
      }
    };

    const promise = new Promise((res: any, rej: any) => {
      const request = async (): Promise<any> => {
        try {
          const _res = await this.contract.methods
            .balanceOfPool(this.id)
            .call();

          process(_res);

          return res(777);
        } catch (error) {
          if (error) return rej;
        }
      };
      return request();
    });

    return promise;
  }

  public async getPastEvents(): Promise<any> {
    const options: any = {
      fromBlock: 0,
      toBlock: "latest",
    };

    const process = (events: any) => {
      if (events) {
        this._transactions = events.map((event: any) => event);

        this._transactions = helpers.removeDuplicates(
          this._transactions,
          this._name
        );
      }
    };

    const promise = new Promise((res: any, rej: any) => {
      const events = async (): Promise<any> => {
        try {
          const events = await this.contract.getPastEvents("Staked", options);
          process(events);

          return res(777);
        } catch (error) {
          if (error) rej(error);
        }
      };
      const e = events();
      return e;
    });

    return promise;
  }

  public async getPineapples(): Promise<any> {
    const promise = new Promise<any>((res: any, rej: any) => {
      const getEarned = (_start: number = 0, _end: number = 500) => {
        const batch = new web3.BatchRequest();

        let start: number = _start;
        let end: number = _end;

        const slices = this._transactions.slice(start, end);

        if (slices.length > 0) {
          const batchRequest = async (calls: any): Promise<any> => {
            const makeBatchRequest = (calls: any) => {
              const promises = calls.map((call: any) => {
                return new Promise((_res, rej) => {
                  const callback = (err: any, res: any) => {
                    // if (err) rej(err);
                    // else
                    _res({
                      amount: res,
                      address: call.address,
                    });
                  };

                  const req = call.web3Method.request(callback);

                  batch.add(req);
                });
              });

              batch.execute();

              return Promise.all(promises);
            };

            const asyncData = await makeBatchRequest(calls);

            asyncData.forEach((r: any) => {
              this.parse(r.amount, r.address);
            });

            start += 500;
            end += 500;

            getEarned(start, end);
          };

          const _batch = slices.map((x: any) => {
            const reqObj = {
              web3Method: this.contract.methods.earned(x.address, this.id).call,
              address: x.address,
            };

            return reqObj;
          });

          batchRequest(_batch);
        } else {
          return res(777);
        }
      };

      return getEarned();
    });

    return promise;
  }

  public parse(earned: any, _tx: any) {
    const decimals = 18;

    if (earned) {
      earned = helpers.applyDecimals(earned, decimals);

      let totalPineapples: any = 0;

      if (this._pineapples) {
        totalPineapples = new BigNumber(this._pineapples)
          .plus(earned)
          .toNumber();
      } else {
        totalPineapples = earned;
      }

      const match: any = this.transactions.find((tx: any) => {
        return tx.address == _tx;
      });

      if (match) {
        this._pineapples = totalPineapples;

        match.pineapples = earned;
      }
    }
  }

  get address(): string {
    return this._address;
  }

  get assetType(): string {
    return this._assetType;
  }

  get name(): string {
    return this._name;
  }

  get pineapples(): string | number | undefined {
    return this._pineapples;
  }

  get price(): string | number {
    return this._price;
  }

  get rank(): string | number | undefined {
    return this._rank;
  }

  set rank(rank: string | number | undefined) {
    this._rank = rank;
  }

  get totalLockedValue(): string | number | undefined {
    return this._totalLockedValue;
  }

  set totalLockedValue(tlv: string | number | undefined) {
    this._totalLockedValue = tlv;
  }

  get totalValue(): string | number | undefined {
    return this._totalValue;
  }

  set totalValue(totalValue: string | number | undefined) {
    this._totalValue = totalValue;
  }

  get transactions(): Array<any> {
    return this._transactions;
  }

  set transactions(payload: Array<any>) {
    this._transactions = payload;
  }
}
