
































































import { Component, Mixins, Watch } from "vue-property-decorator";
import Vue from "vue";

import TopBar from "../../navigator/top/index.vue";
import MiddleBar from "../../navigator/middle/index.vue";
// Mixins
import ToolMixin from "../../../mixins/accessors/tool";
import NftMixin from "../../../mixins/accessors/nft";
// Event Bus
import { EventBus } from "../../../event-bus/index";

import Axios from "../../../middleware/api";

@Component({
  components: { TopBar, MiddleBar },
})
export default class Search extends Mixins(NftMixin, ToolMixin) {
  isLoading: boolean = false;
  items: Array<any> = [];
  model = null;
  search = null;

  typingTimer: any = "";
  doneTypingInterval: number = 1000;

  @Watch("search")
  searchAction(val: any) {
    // Items have already been loaded
    if (this.items.length > 0) return;

    this.isLoading = true;

    // Lazily load input items
    fetch("https://api.coingecko.com/api/v3/coins/list")
      .then((res) => res.clone().json())
      .then((res) => {
        this.items = res;
      })
      .catch((err) => {
        console.log(err);
      })
      .finally(() => (this.isLoading = false));
  }

  private onInputChange(): void {
    clearTimeout(this.typingTimer);
    this.typingTimer = setTimeout(
      this.querySelections,
      this.doneTypingInterval
    );
  }

  private async querySelections(v: any): Promise<any> {
    this.isLoading = true;
    this.items.splice(0, this.items.length);

    const dataAllocation = (response: any) => {
      const responseClient: any =
        response.data.FDBS.Manager.TableList[0].RowList;

      for (const obj of responseClient) {
        obj["Original"]["name"] =
          obj["Original"]["NameFirst"] + " " + obj["Original"]["NameLast"];

        this.items.push(obj["Original"]);
      }

      this.isLoading = false;
    };
    if (this.dataSearch != null) {
      if (this.dataSearch.length > 0) {
        try {
          // const res = await Axios.searchFilter(this.$store.state);
          // dataAllocation(res);
        } catch (error) {
          console.log(error);
          throw error;
        }
      } else {
        this.items.splice(0, this.items.length);
        this.isLoading = false;
      }
    }
  }

  private closeSearch(): void {
    this.$emit("close");
  }
}
