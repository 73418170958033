









import { Component, Mixins, Watch } from "vue-property-decorator";
import Vue from "vue";

import TopBar from "./top/index.vue";
import MiddleBar from "./middle/index.vue";
import BottomBar from "./bottom/index.vue";
// Mixins
import ToolMixin from "../../mixins/accessors/tool";
import NftMixin from "../../mixins/accessors/nft";
// Event Bus
import { EventBus } from "../../event-bus/index";

import Axios from "../../middleware/api";

@Component({
  components: { TopBar, MiddleBar, BottomBar },
})
export default class Navigator extends Mixins(NftMixin, ToolMixin) {
  $refs!: {
    toolbar: any;
  };

  mounted() {
    document.documentElement.style.setProperty("--toolbarHeight", "60px");
  }
  // onMutate() {
  //   let height: any = 0;
  //   console.log(height)
  //   const toolbar: any = this.$refs.toolbar;
  //   if (toolbar) {
  //     height = `${toolbar.$el.offsetHeight}px`;
  //   }
  //   document.documentElement.style.setProperty("--toolbarHeight", height);
  // }
}
