import { Schema1 } from "../schemas/schema-1";

import Axios from "../../../middleware/api";

import Web3 from "web3";
import infura from "../../../web3/web3";

import helpers from "../../../helpers/helpers";
import BigNumber from "bignumber.js";

const web3: Web3 = new Web3(infura);

export class LpGenesis extends Schema1 {
  public subContracts: Array<any> = [
    {
      id: "uniswap",
      abi: undefined,
      address: "0x5dfbe95925ffeb68f7d17920be7b313289a1a583",
      contract: undefined,
    },
  ];

  constructor(
    assetPrice: string | number,
    name: string = "LP Genesis",
    address: string = "0x3321568445206E9311565A2C5397FC776BA71dde"
  ) {
    super(assetPrice, name, address);
    this.initialize();
  }

  private async initialize(): Promise<any> {
    const abi = await super.getAbi();

    await super.getContract(abi);

    await this.getSubContractsAbi();

    await this.supply();

    await super.getPastEvents();

    await super.getPineapples();
  }

  private async getSubContractsAbi(): Promise<any> {
    const process = async (response: any): Promise<any> => {
      return new Promise((res: any, rej: any) => {
        try {
          response.forEach((result: any) => {
            const target = this.subContracts.find((contract: any) => {
              const match = contract.id == result.id;

              return match;
            });

            if (target) {
              target["abi"] = result.abi;
              target["contract"] = new web3.eth.Contract(
                JSON.parse(result.abi),
                target.address
              );
            }
          });
          res("resolved");
        } catch (error) {
          return rej;
        }
      });
    };

    const abi = async (pool: any): Promise<any> => {
      const res = await Axios.getAbi(pool.address);
      const data: any = {
        abi: res.data.result,
        address: pool.address,
        id: pool.id,
      };

      return data;
    };

    return new Promise((res: any, rej: any) => {
      try {
        const promiseSlice = async (
          start: number = 0,
          end: number = 5
        ): Promise<any> => {
          const section: Array<any> = this.subContracts.slice(start, end);

          const req = section.map((x: any) => abi(x));
          const results = await Promise.all(req);

          await process(results);

          return res("resolved");
        };
        return promiseSlice();
      } catch (error) {
        return rej;
      }
    });
  }

  public async supply(): Promise<any> {
    const process = async (res: any) => {
      if (res) {
        const decimals: number = 8;
        const total = helpers.applyDecimals(res[1], decimals);

        const lpTokens = await this.subContracts[0].contract.methods
          .totalSupply()
          .call();

        const lpTokensStaked = await this.subContracts[0].contract.methods
          .balanceOf(this.address)
          .call();

        const b = new BigNumber(lpTokensStaked);

        const percent = b
          .div(lpTokens)
          .decimalPlaces(4)
          .toNumber();

        this._totalValue = new BigNumber(total)
          .multipliedBy(percent)
          .toNumber();

        this._totalLockedValue = new BigNumber(this.price)
          .multipliedBy(this._totalValue)
          .toNumber();
      }
    };

    const promise = new Promise((res: any, rej: any) => {
      const request = async (): Promise<any> => {
        try {
          const _res = await this.subContracts[0].contract.methods
            .getReserves()
            .call();

          process(_res);

          return res("resolved");
        } catch (error) {
          if (error) return rej;
        }
      };
      const r = request();

      return r;
    });

    return promise;
  }
}
