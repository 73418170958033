import axios from "axios";

// Configuring axios defaults
axios.defaults.headers.common.Accept = "application/json; charset=utf-8";
axios.defaults.headers.post["Content-Type"] = "application/json; charset=utf-8";
// axios.defaults.baseURL = "https://www.qscorpio.com:3066";

export const opensea = axios.create({
  baseURL: "https://api.opensea.io",
  responseType: "json",
  headers: {
    "X-API-KEY": "f5bf4b81b310490b9fd1840521a9d2fc",
  },
});

export const etherscan = axios.create({
  baseURL: "https://api.etherscan.io",
  responseType: "json",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});

export const coingecko = axios.create({
  baseURL: "https://api.coingecko.com/api/v3",
  responseType: "json",
  headers: {
    "Content-Type": "application/json; charset=utf-8",
  },
});
