




































































































































































// Vue property decorator
import {
  Component,
  Prop,
  Emit,
  Vue,
  Mixins,
  Watch,
} from "vue-property-decorator";
// Mixins
import ToolMixin from "../../mixins/accessors/tool";
import NftMixin from "../../mixins/accessors/nft";
// Axios
import Axios from "../../middleware/api";
// EventBus
import { EventBus } from "../../event-bus";
// import web3 from '../contracts/web3.js';
import Web3 from "web3";
import BigNumber from "bignumber.js";

// const web3 = new Web3(
new Web3.providers.HttpProvider(
  "https://mainnet.infura.io/v3/e1a2634a66fc4f4c816926b47ae06164"
);
// );
const web3 = new Web3(Web3.givenProvider);

@Component({
  components: {},
  filters: {
    rotateColor(payload: any) {
      const colors: Array<string> = [
        "primary",
        "red",
        "purple",
        "green",
        "amber",
      ];

      const getRandomInt = (max: number) => {
        return Math.floor(Math.random() * Math.floor(max));
      };

      return colors[getRandomInt(4)];
    },

    formatNumber(payload: any) {
      if (payload) {
        const thousands_seperators = (num: any) => {
          const num_parts = num.toString().split(".");
          num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return num_parts.join(".");
        };
        const total = thousands_seperators(payload);

        if (payload == 0) {
          return "N/A";
        } else {
          return `$${total}`;
        }
      }
    },

    formatDecimal(payload: any) {
      const thousands_seperators = (num: any) => {
        const num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return num_parts.join(".");
      };
      let total = (Math.round(Number(payload) * 100) / 100).toFixed(2);

      total = thousands_seperators(total);
      return total;
    },

    formatDecimal2(payload: any) {
      const thousands_seperators = (num: any) => {
        const num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return num_parts.join(".");
      };
      let total = (Math.round(Number(payload) * 100) / 100).toFixed(2);
      total = thousands_seperators(total);
      if (total == undefined) {
        return "N/A";
      } else {
        return `$${total}`;
      }
    },
  },
})
export default class MyCollection extends Mixins(NftMixin, ToolMixin) {
  cancelInterval: any = null;
  dialog: boolean = false;
  headers: Array<object> = [
    {
      text: "#",
      value: "nft_rank",
      class: "sticky-header",
    },
    {
      text: "Asset",
      value: "image_thumbnail_url",
      class: "sticky-header",
    },
    { text: "Title", value: "name", class: "sticky-header" },
    { text: "Value", value: "last_sale_usd", class: "sticky-header" },
    { text: "Quantity", value: "total_quantity", class: "sticky-header" },
    { text: "Total Value", value: "total_value", class: "sticky-header" },
    {
      text: "",
      value: "action",
      sortable: false,
      class: "sticky-header",
    },
  ];
  imageUrl: string = "";
  item: number = 0;
  itemsPerPage: number = 50;
  itemsPerPageArray: number[] = [25, 50, 100];
  loading: boolean = false;
  loadMoreData: boolean = true;
  offset: number = 0;
  page: number = 1;
  search: string = "";
  title: string = "My Collection";

  beforeDestroy() {
    clearInterval(this.cancelInterval);
  }

  get items(): Array<any> {
    this.myCollection.sort(this.compare);
    this.myCollection.forEach((pool: any, i: number) => {
      this.$set(pool, "nft_rank", i + 1);
    });
    return this.myCollection;
  }

  get totalNft(): string | number {
    const total = this.items.reduce(
      (prev, cur) => prev + parseInt(cur.total_quantity),
      0
    );
    return total;
  }

  get portfolioValue(): string | number {
    const total = this.items.reduce((prev, cur) => prev + cur.total_value, 0);
    return total;
  }

  get numberOfPages(): number {
    return Math.ceil(this.items.length / this.itemsPerPage);
  }

  private nextPage() {
    if (this.page + 1 <= this.numberOfPages) this.page += 1;
  }

  private formerPage() {
    if (this.page - 1 >= 1) this.page -= 1;
  }

  private updateItemsPerPage(number: number) {
    this.itemsPerPage = number;
  }

  private compare(a: any, b: any) {
    if (a.total_value > b.total_value) {
      return -1;
    }
    if (a.total_value < b.total_value) {
      return 1;
    }
    return 0;
  }

  private renderFullImage(url: string): void {
    this.dialog = true;
    this.imageUrl = url;
  }

  private unlockWallet(): void {
    EventBus.$emit("UNLOCK_WALLET");
  }
}
