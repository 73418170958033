import { Component } from "vue-property-decorator";
import Vue from "vue";

@Component
export default class ToolMixin extends Vue {
  /*
   * TOOL MODULE
   */
  get contentProgress(): boolean {
    return this.$store.state.tool.contentProgress;
  }

  set contentProgress(payload: boolean) {
    this.$store.commit("tool/UPDATE_CONTENT_PROGRESS", payload);
  }

  get darkTheme(): boolean {
    return this.$store.state.tool.darkTheme;
  }

  set darkTheme(payload: boolean) {
    this.$store.commit("tool/UPDATE_DARK_THEME", payload);
  }

  get dataSearch(): string {
    return this.$store.state.tool.dataSearch;
  }

  set dataSearch(payload: string) {
    this.$store.commit("tool/UPDATE_DATA_SEARCH", payload);
  }

  get imageDialog(): boolean {
    return this.$store.state.tool.imageDialog;
  }

  set imageDialog(payload: boolean) {
    this.$store.commit("tool/UPDATE_IMAGE_DIALOG", payload);
  }

  get apiOffset(): any {
    return this.$store.state.tool.apiOffset;
  }

  set apiOffset(payload: any) {
    this.$store.commit("tool/UPDATE_API_OFFSET", payload);
  }

  get sideNav(): any {
    return this.$store.state.tool.sideNav;
  }

  set sideNav(payload: any) {
    this.$store.commit("tool/UPDATE_SIDE_NAV", payload);
  }

  get unlocked(): any {
    return this.$store.state.tool.unlocked;
  }

  set unlocked(payload: any) {
    this.$store.commit("tool/UPDATE_UNLOCKED", payload);
  }
}
