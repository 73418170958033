import { render, staticRenderFns } from "./frank-wilder.vue?vue&type=template&id=0c93ad2c&"
import script from "./frank-wilder.vue?vue&type=script&lang=ts&"
export * from "./frank-wilder.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports