import Vue from "vue";
import Vuex from "vuex";
import { tool } from "./modules/tool";
import { nft } from "./modules/nft";

Vue.use(Vuex);

export default new Vuex.Store({
  modules: {
    tool,
    nft,
  },
});
