import { NftState } from "@/interfaces";

const initialState = (): NftState => {
  return {
    badgerPrice: 0,
    yopPrice: 0,
    diggPrice: 0,
    collection: "meme-ltd",
    collections: [
      { name: "Decentraland Wearables", collection: "decentraland-wearables" },
      { name: "Meme Ltd", collection: "meme-ltd" },
    ],
    memeLtdContract: {
      abi: undefined,
      address: "0xe4605d46fd0b3f8329d936a8b258d69276cba264",
      contract: undefined,
    },
    memePrice: 0,
    myCollection: [],
    myWallet: "",
    totalNfts: 0,
    totalMarketCap: 0,
    pools: [],
  };
};
export const nft = {
  namespaced: true,
  state: initialState,

  mutations: {
    UPDATE_BADGER_PRICE(state: any, payload: any) {
      state.badgerPrice = payload;
    },
    UPDATE_YOP_PRICE(state: any, payload: any) {
      state.yopPrice = payload;
    },
    UPDATE_DIGG_PRICE(state: any, payload: any) {
      state.diggPrice = payload;
    },
    UPDATE_COLLECTION(state: any, payload: any) {
      state.collection = payload;
    },
    UPDATE_POOLS(state: any, payload: any) {
      state.pools = payload;
    },
    UPDATE_MEME_LTD_CONTRACT(state: any, payload: any) {
      state.memeLtdContract = payload;
    },
    UPDATE_MEME_PRICE(state: any, payload: any) {
      state.memePrice = payload;
    },
    UPDATE_MY_COLLECTION(state: any, payload: any) {
      state.myCollection = payload;
    },
    UPDATE_MY_WALLET(state: any, payload: any) {
      state.myWallet = payload;
    },
    UPDATE_TOTAL_NFTS(state: any, payload: any) {
      state.totalNfts = payload;
    },
    UPDATE_TOTAL_MARKET_CAP(state: any, payload: any) {
      state.totalMarketCap = payload;
    },
    RESET_STATE(state: NftState) {
      const clonedState: NftState = initialState();

      Object.keys(clonedState).forEach((key) => {
        state[key] = clonedState[key];
      });
    },
  },

  getters: {
    collections(state: any) {
      return state.collections;
    },
  },
};
