









// Vue property decorator
import {
  Component,
  Prop,
  Emit,
  Vue,
  Mixins,
  Watch,
} from "vue-property-decorator";
// Mixins
import ToolMixin from "../../mixins/accessors/tool";
import NftMixin from "../../mixins/accessors/nft";
// Components
import MemeLtd from "./meme-ltd/index.vue";
import DecentralandWearables from "./decentraland-wearables/index.vue";
// Axios
import Axios from "../../middleware/api";
// EventBus
import { EventBus } from "../../event-bus";

@Component({
  components: { MemeLtd, DecentralandWearables },
})
export default class MainSection extends Mixins(NftMixin, ToolMixin) {}
