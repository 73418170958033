
















































































































































































// Vue property decorator
import {
  Component,
  Prop,
  Emit,
  Vue,
  Mixins,
  Watch,
} from "vue-property-decorator";
// Mixins
import ToolMixin from "../../../mixins/accessors/tool";
import NftMixin from "../../../mixins/accessors/nft";
// Axios
import Axios from "../../../middleware/api";
// EventBus
import { EventBus } from "../../../event-bus";

@Component({
  components: {},
})
export default class BaseTemplate extends Mixins(NftMixin, ToolMixin) {
  cancelInterval: any = null;

  item: number = 0;
  itemsPerPage: number = 50;
  itemsPerPageArray: number[] = [25, 50, 100];
  loadMoreData: boolean = true;
  page: number = 1;
  search: string = "";
  pagination: any = {
    sortBy: null,
    descending: false,
    page: 1,
    itemsPerPage: 50,
  };

  @Prop(Boolean) loading!: boolean;
  @Prop(String) imageUrl!: string;
  @Prop(String) name!: string;
  @Prop(Array) items!: Array<any>;
  @Prop(Array) headers: any;

  beforeDestroy() {
    clearInterval(this.cancelInterval);
  }

  created() {
    this.loading = true;
  }

  @Watch("pagination", { deep: true })
  handler() {
    this.sortData().then((data: any) => {
      this.items = data.items;
    });
  }

  get labelName(): string {
    const collection = this.collections.find(
      (x: any) => x.collection == this.collection
    );

    return collection.name;
  }

  get numberOfPages(): number {
    return Math.ceil(this.items.length / this.itemsPerPage);
  }

  private nextPage() {
    if (this.page + 1 <= this.numberOfPages) this.page += 1;
  }

  private formerPage() {
    if (this.page - 1 >= 1) this.page -= 1;
  }

  private updateItemsPerPage(number: number) {
    this.itemsPerPage = number;
  }

  private changeSort(column: any): void {
    if (this.pagination.sortBy === column) {
      this.pagination.descending = !this.pagination.descending;
    } else {
      this.pagination.sortBy = column;
      this.pagination.descending = false;
    }
  }

  sortData() {
    return new Promise((resolve, reject) => {
      const { sortBy, descending, page, itemsPerPage } = this.pagination;

      let items = this.items;
      const total = items.length;

      if (sortBy) {
        items = items.sort((a, b) => {
          const sortA = a[sortBy];
          const sortB = b[sortBy];

          if (descending) {
            if (sortA < sortB) return 1;
            if (sortA > sortB) return -1;
            return 0;
          } else {
            if (sortA < sortB) return -1;
            if (sortA > sortB) return 1;
            return 0;
          }
        });
      }

      if (itemsPerPage > 0) {
        items = items.slice((page - 1) * itemsPerPage, page * itemsPerPage);
      }

      setTimeout(() => {
        this.loading = false;
        resolve({
          items,
          total,
        });
      }, 1000);
    });
  }
}
