import { Schema1 } from "../schemas/schema-1";

export class BigComicArt extends Schema1 {
  constructor(
    assetPrice: string | number,
    name: string = "Artist Drop 4 - BigComicArt",
    address: string = "0x83c6DB179c0202a0c6d8EbDAa185B80CccB9EFf8"
  ) {
    super(assetPrice, name, address);
    this.initialize();
  }

  private async initialize(): Promise<any> {
    const abi = await super.getAbi();

    super.getContract(abi);

    await super.totalSupply();

    await super.getPastEvents();

    await super.getPineapples();
  }
}
