import { Schema2 } from "../schemas/schema-2";

import Axios from "../../../middleware/api";

import Web3 from "web3";
import infura from "../../../web3/web3";

import helpers from "../../../helpers/helpers";
import BigNumber from "bignumber.js";

const web3: Web3 = new Web3(infura);

export class LpBadgerDao extends Schema2 {
  public subContracts: Array<any> = [
    {
      id: "uniswapV2Pair",
      abi: undefined,
      address: "0xcD7989894bc033581532D2cd88Da5db0A4b12859",
      contract: undefined,
    },
    {
      id: "badgerSettToken",
      abi: undefined,
      proxy: "0x235c9e24D3FB2FAFd58a2E49D454Fdcd2DBf7FF1",
      address: "0xE4Ae305b08434bF3D74e0086592627F913a258A9",
      contract: undefined,
    },
  ];

  private proxy: string = "0x0c79406977314847a9545b11783635432d7fe019";

  constructor(
    assetPrice: string | number,
    name: string = "Featured Drop - LP Badger DAO",
    address: string = "0xa8e9E4A044A95cd80d5aC7e288fE62641eF60798",
    id: number = 0,
    assetType: string = "BADGER"
  ) {
    super(assetPrice, name, address, id, assetType);
    this.initialize();
  }

  private async initialize(): Promise<any> {
    const abi = await super.getAbi();

    await super.getContract(abi);

    await this.getSubContractsAbi();

    await this.supply();

    await super.getPastEvents();

    await super.getPineapples();
  }

  private async getSubContractsAbi(): Promise<any> {
    const process = async (response: any): Promise<any> => {
      return new Promise((res: any, rej: any) => {
        try {
          response.forEach((result: any) => {
            const target = this.subContracts.find((contract: any) => {
              const match = contract.id == result.id;

              return match;
            });

            if (target) {
              if (target.id == "badgerSettToken") {
                target["abi"] = result.abi;
                target["contract"] = new web3.eth.Contract(
                  JSON.parse(result.abi),
                  target.proxy
                );
              } else {
                target["abi"] = result.abi;
                target["contract"] = new web3.eth.Contract(
                  JSON.parse(result.abi),
                  target.address
                );
              }
            }
          });
          res("resolved");
        } catch (error) {
          return rej;
        }
      });
    };

    const abi = async (pool: any): Promise<any> => {
      const res = await Axios.getAbi(pool.address);
      const data: any = {
        abi: res.data.result,
        address: pool.address,
        id: pool.id,
      };

      return data;
    };

    return new Promise((res: any, rej: any) => {
      try {
        const promiseSlice = async (
          start: number = 0,
          end: number = 5
        ): Promise<any> => {
          const section: Array<any> = this.subContracts.slice(start, end);

          const req = section.map((x: any) => abi(x));
          const results = await Promise.all(req);

          await process(results);

          return res("resolved");
        };
        return promiseSlice();
      } catch (error) {
        return rej;
      }
    });
  }

  public async supply(): Promise<any> {
    const process = (
      _totalBadger: any,
      _totalSupplyUniV2: any,
      _balanceOfAddress: any,
      _totalSupplyBUniV2: any,
      _balanceOfPool: any
    ) => {
      const totalBadger = new BigNumber(_totalBadger);
      const totalSupplyUniV2 = new BigNumber(_totalSupplyUniV2);
      const balanceOfAddress = new BigNumber(_balanceOfAddress);
      const totalSupplyBUniV2 = new BigNumber(_totalSupplyBUniV2);
      const balanceOfPool = new BigNumber(_balanceOfPool);

      const percentStakedInBadger = balanceOfAddress.div(totalSupplyUniV2);
      const badger = totalBadger.multipliedBy(percentStakedInBadger);
      const stakedInMemeFarm = balanceOfPool.div(totalSupplyBUniV2);

      const total = badger.multipliedBy(stakedInMemeFarm);

      this._totalValue = total.toNumber();

      super._totalLockedValue = new BigNumber(this._price)
        .multipliedBy(total)
        .toNumber();
    };

    const promise = new Promise((res: any, rej: any) => {
      const request = async (): Promise<any> => {
        try {
          const decimals: number = 18;
          // Total Badger
          const totalBadger = await this.subContracts[0].contract.methods
            .getReserves()
            .call();

          const _totalBadger = helpers.applyDecimals(totalBadger[1], decimals);
          // End

          // Total Supply UniV2
          const totalSupplyUniV2 = await this.subContracts[0].contract.methods
            .totalSupply()
            .call();

          const _totalSupplyUniV2 = helpers.applyDecimals(
            totalSupplyUniV2,
            decimals
          );
          // End

          // Balance of Address
          const balanceOfAddress = await this.subContracts[0].contract.methods
            .balanceOf(this.proxy)
            .call();

          const _balanceOfAddress = helpers.applyDecimals(
            balanceOfAddress,
            decimals
          );
          // End

          // Total Supply b-UniV2
          const totalSupplyBUniV2 = await this.subContracts[1].contract.methods
            .totalSupply()
            .call();

          const _totalSupplyBUniV2 = helpers.applyDecimals(
            totalSupplyBUniV2,
            decimals
          );
          // End

          // Balance of Pool
          const balanceOfPool = await this.subContracts[1].contract.methods
            .balanceOf(this.address)
            .call();

          const _balanceOfPool = helpers.applyDecimals(balanceOfPool, decimals);
          // End

          process(
            _totalBadger,
            _totalSupplyUniV2,
            _balanceOfAddress,
            _totalSupplyBUniV2,
            _balanceOfPool
          );

          return res("resolved");
        } catch (error) {
          if (error) return rej;
        }
      };
      return request();
    });

    return promise;
  }
}
