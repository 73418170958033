




import { Component, Prop, Vue } from "vue-property-decorator";
import MyCollectionComponent from "@/components/view_components/MyCollectionComponent.vue";
// Importing Event Bus
import { EventBus } from "../event-bus/index";

@Component({
  components: {
    MyCollectionComponent,
  },
})
export default class MyCollection extends Vue {}
