




import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import Vue from "vue";
import { SnotifyPosition, SnotifyStyle } from "vue-snotify";

// Importing Event Bus
import { EventBus } from "../../event-bus";
// Mixins
import ToolMixin from "../../mixins/accessors/tool";

@Component({
  components: {},
})
export default class Redirect extends Mixins(ToolMixin) {
  created(): void {
    EventBus.$on("401_REDIRECT", () => this.errorRedirect());
    EventBus.$on("UNKNOWN_ERROR", () => this.unknownError());
    EventBus.$on("USER_CONFIRMATION", (payload: any) =>
      this.userConfirmation(payload)
    );
    EventBus.$on("CLIENT_REQUIRED", (payload: any) =>
      this.clientRequired(payload)
    );
  }
  errorRedirect(): void {
    const _this: any = this;
    _this.$snotify.config.global.maxOnScreen = 1;

    _this.$snotify.error(`Your session has expired`, {
      timeout: 2000,
      showProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      preventDuplicates: true,
      global: {
        preventDuplicates: true,
      },
    });

    setTimeout(() => {
      this.session_destroy();
    }, 2000);
  }

  unknownError(): void {
    const _this: any = this;
    _this.$snotify.config.global.maxOnScreen = 1;

    _this.$snotify.error(`An error has occured, please try again later.`, {
      timeout: 2000,
      showProgressBar: false,
      closeOnClick: false,
      pauseOnHover: false,
      preventDuplicates: true,
      global: {
        preventDuplicates: true,
      },
    });
  }

  // Delete appointment by delete key press
  userConfirmation(payload: any): void {
    const _this: any = this;
    const _delete: string = "Continue";

    _this.$snotify.config.global.maxOnScreen = 1;

    _this.$snotify.confirm(
      `You are logging in as ${payload.decryptedData.firstName} ${payload.decryptedData.lastName}`,
      null,
      {
        timeout: 15000,
        showProgressBar: true,
        closeOnClick: false,
        pauseOnHover: false,
        preventDuplicates: true,
        position: "centerCenter",
        global: {
          preventDuplicates: true,
        },
        buttons: [
          {
            text: "Yes. That's me.",
            action: payload.yesFx,
            bold: false,
          },
          {
            text: "Nope. Not me!",
            action: payload.noFx,
          },
        ],
      }
    );
  }

  clientRequired(payload: any): void {
    const _this: any = this;
    const _delete: string = "Continue";

    _this.$snotify.config.global.maxOnScreen = 1;

    const init = (toast: any) => {
      _this.$snotify.remove(toast.id);
      EventBus.$emit("IS_ACTIVE");
    };

    _this.$snotify.html(
      `<div class="snotifyToast__title text-center">Client Required</div>`,
      {
        timeout: 0,
        showProgressBar: false,
        backdrop: 0.6,
        closeOnClick: false,
        pauseOnHover: false,
        preventDuplicates: true,
        position: "centerCenter",
        global: {
          preventDuplicates: true,
        },
        buttons: [
          {
            text: "Select a client",
            action: init,
            bold: false,
          },
        ],
      }
    );
  }

  // Log user out
  session_destroy(): void {
    EventBus.$emit("LOG_OUT");
  }
}
