import { Schema1 } from "../schemas/schema-1";

export class Jon extends Schema1 {
  constructor(
    assetPrice: string | number,
    name: string = "Artist Drop 2 - Jon",
    address: string = "0x9879286DdFc1238F67D47265C46bAf55B955c326"
  ) {
    super(assetPrice, name, address);
    this.initialize();
  }

  private async initialize(): Promise<any> {
    const abi = await super.getAbi();

    super.getContract(abi);

    await super.totalSupply();

    await super.getPastEvents();

    await super.getPineapples();
  }
}
