import { Schema2 } from "../schemas/schema-2";

export class Fewocious extends Schema2 {
  constructor(
    assetPrice: string | number,
    name: string = "Artist Drop 8 - Fewocious",
    address: string = "0x1d90d50D5dd04FA7c8BeF89aA5872F0701Be7982",
    id: number = 6
  ) {
    super(assetPrice, name, address, id);
    this.initialize();
  }

  private async initialize(): Promise<any> {
    const abi = await super.getAbi();

    super.getContract(abi);

    await super.totalSupply();

    await super.getPastEvents();

    await super.getPineapples();
  }
}
