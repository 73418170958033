var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"512"},model:{value:(_vm.dialog),callback:function ($$v) {_vm.dialog=$$v},expression:"dialog"}},[_c('v-card',[_c('v-img',{staticClass:"customHeight zIndex",attrs:{"aspect-ratio":6 / 9,"contain":"","src":_vm.imageUrl}})],1)],1),_c('h1',{staticClass:"text-left h2Font",class:{
      'white--text': _vm.$vuetify.theme.dark,
      'black--text': !_vm.$vuetify.theme.dark,
      mobileFont: _vm.$vuetify.breakpoint.smAndDown,
    }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.unlocked)?_c('h4',{staticClass:"font-weight-normal smallText",class:_vm.$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-left'},[_c('span',{class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text'},[_vm._v("Total NFTs: "+_vm._s(_vm.totalNft))]),_c('span',{staticClass:"ml-5 smallText",class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text'},[_vm._v("Portfolio Value: "+_vm._s(_vm._f("formatDecimal2")(_vm.portfolioValue)))])]):_vm._e(),(_vm.unlocked)?_c('v-data-table',{class:{ removeSticky: _vm.$vuetify.breakpoint.smAndDown },attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"loading":_vm.loading,"loading-text":"Loading... Please wait","hide-default-footer":"","mobile-breakpoint":"0"},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.image_thumbnail_url",fn:function(ref){
    var item = ref.item;
return [_c('v-img',{staticClass:"grey lighten-2 mt-2 mb-2 imageHover",attrs:{"src":item.image_thumbnail_url,"aspect-ratio":6 / 9,"width":_vm.$vuetify.breakpoint.smAndDown ? 49 : 70},on:{"click":function($event){return _vm.renderFullImage(item.image_original_url)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm._f("rotateColor")('grey')}})],1)]},proxy:true}],null,true)})]}},{key:"item.last_sale_usd",fn:function(ref){
    var item = ref.item;
return [(item.last_sale_usd)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDecimal2")(item.last_sale_usd))+" ")]):_c('span',[_vm._v(" N/A ")])]}},{key:"item.total_value",fn:function(ref){
    var item = ref.item;
return [(item.total_value)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDecimal2")(item.total_value))+" ")]):_c('span',[_vm._v(" N/A ")])]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"white--text smallText text-capitalize",attrs:{"color":"#6600cc","href":((item.permalink) + "?ref=0x30009765D2BFD320931F5B29Fc5523BfabEEb3D5"),"medium":"","target":"_blank"}},[_c('img',{staticClass:"mr-2",attrs:{"height":"30px","color":"red","src":require("../../assets/open-sea-logo.svg")}}),_vm._v(" OpenSea ")])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"text-right dataFooter py-3 px-2"},[_c('span',{staticClass:"grey--text"},[_vm._v(" Rows per page ")]),_c('v-menu',{staticClass:"menuIndex",attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"dark":"","text":"","color":"primary"}},on),[_vm._v(" "+_vm._s(_vm.itemsPerPage)+" "),_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,1421263725)},[_c('v-list-item-group',{attrs:{"color":"indigo"},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},[_c('v-list',_vm._l((_vm.itemsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateItemsPerPage(number)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(number)+" ")])],1)],1)}),1)],1)],1),_c('v-icon',{on:{"click":_vm.formerPage}},[_vm._v(" mdi-chevron-left ")]),_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.page)+" of "+_vm._s(_vm.numberOfPages)+" ")]),_c('v-icon',{on:{"click":_vm.nextPage}},[_vm._v(" mdi-chevron-right ")])],1)]},proxy:true}],null,true)}):_c('div',{staticClass:"minHeight"},[_c('p',{class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text'},[_vm._v(" Connect your MetaMask wallet to view your NFT collection and value ")]),_c('v-btn',{staticClass:"pl-2 pr-2 white--text",attrs:{"color":"#6600cc"},on:{"click":function($event){return _vm.unlockWallet()}}},[_vm._v(" Connect Wallet ")])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }