import Vue from "vue";
import App from "./App.vue";
import "./validation/index.ts"
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import Snotify from "vue-snotify";
import "vue-snotify/styles/material.css";
import { Datetime } from "vue-datetime";
import "vue-datetime/dist/vue-datetime.css";
import VueCookies from "vue-cookies";
import VueTheMask from "vue-the-mask";
import Animate from 'animate.css';

Vue.config.productionTip = false;

const options: any = {
  global: {
    maxOnScreen: 1
  },
};

Vue.use(Animate);
Vue.use(VueCookies);
Vue.use(VueTheMask);
Vue.use(Snotify, options);
Vue.use(Datetime);

new Vue({
  router,
  store,
  vuetify,
  render: (h) => h(App),
}).$mount("#app");
