



import { Component, Mixins, Watch } from "vue-property-decorator";
import Vue from "vue";
// Components
import ContentProgress from "../loaders/content-progress/index.vue";
import MemeFarms from "../meme-farms/index.vue";
import Footer from "../footer/index.vue";
// Mixins
import ToolMixin from "../../mixins/accessors/tool";
import NftMixin from "../../mixins/accessors/nft";
// Event Bus
import { EventBus } from "../../event-bus/index";
// Crypto-js
import CryptoJS from "crypto-js";
// Middleware
import Axios from "../../middleware/api";

@Component({
  components: {
    ContentProgress,
    Footer,
    MemeFarms,
  },
})
export default class OverviewComponent extends Mixins(NftMixin, ToolMixin) {}
