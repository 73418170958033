var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{key:_vm.componentKey},[_c('h1',{staticClass:"text-left h2Font",class:{
      'white--text': _vm.$vuetify.theme.dark,
      'black--text': !_vm.$vuetify.theme.dark,
      mobileFont: _vm.$vuetify.breakpoint.smAndDown,
    }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),_c('h4',{staticClass:"font-weight-normal",class:_vm.$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-left'},[_c('span',{staticClass:"smallText",class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text'},[_vm._v("Total MEME Locked: "+_vm._s(_vm._f("formatDecimal")(_vm.totalMemeValue)))]),_c('span',{staticClass:"ml-5 smallText",class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text'},[_vm._v("MEME TLV: "+_vm._s(_vm._f("formatNumber")(_vm.lockedMemeValue)))])]),_c('v-data-table',{staticClass:"tableEnd",class:{ removeSticky: _vm.$vuetify.breakpoint.mdAndDown },attrs:{"search":_vm.search,"headers":_vm.headers,"items":_vm.items,"item-key":"name","items-per-page":_vm.itemsPerPage,"page":_vm.page,"loading":_vm.loading,"loading-text":"Loading... Please wait","hide-default-footer":"","mobile-breakpoint":"0"},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.totalValue",fn:function(ref){
    var item = ref.item;
return [(!item.totalValue)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm._f("rotateColor")('grey')}}):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDecimal")(item.totalValue))+" "+_vm._s(item.assetType)+" ")])]}},{key:"item.address",fn:function(ref){
    var item = ref.item;
return [_c('a',{class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text',attrs:{"href":("https://etherscan.io/address/" + (item.address)),"target":"_blank"}},[_vm._v(" "+_vm._s(item.address)+" ")])]}},{key:"item.pineapples",fn:function(ref){
    var item = ref.item;
return [(!item.pineapples)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm._f("rotateColor")('grey')}}):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDecimal")(item.pineapples))+" ")])]}},{key:"item.totalLockedValue",fn:function(ref){
    var item = ref.item;
return [(!item.totalLockedValue)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm._f("rotateColor")('grey')}}):_c('span',[_vm._v(" $"+_vm._s(_vm._f("formatDecimal")(item.totalLockedValue))+" ")])]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_c('v-btn',{staticClass:"white--text smallText mt-4 mb-4",attrs:{"color":"#6600cc"},on:{"click":function($event){return _vm.route(item)}}},[_c('img',{attrs:{"height":"25px","color":"red","src":require("../../assets/pinelogo.png")}}),_c('span',{staticClass:"white--text"},[_vm._v("Leaderboard")])])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"text-right dataFooter py-3 px-2"},[_c('span',{staticClass:"grey--text"},[_vm._v(" Rows per page ")]),_c('v-menu',{staticClass:"menuIndex",attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"dark":"","text":"","color":"primary"}},on),[_vm._v(" "+_vm._s(_vm.itemsPerPage)+" "),_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-list-item-group',{attrs:{"color":"indigo"},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},[_c('v-list',_vm._l((_vm.itemsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateItemsPerPage(number)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(number)+" ")])],1)],1)}),1)],1)],1),_c('v-icon',{on:{"click":_vm.formerPage}},[_vm._v(" mdi-chevron-left ")]),_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.page)+" of "+_vm._s(_vm.numberOfPages)+" ")]),_c('v-icon',{on:{"click":_vm.nextPage}},[_vm._v(" mdi-chevron-right ")])],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }