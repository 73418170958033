



























































































































// Vue property decorator
import {
  Component,
  Prop,
  Emit,
  Vue,
  Mixins,
  Watch,
} from "vue-property-decorator";
// Mixins
import ToolMixin from "../../../mixins/accessors/tool";
import NftMixin from "../../../mixins/accessors/nft";
// Axios
import Axios from "../../../middleware/api";
// EventBus
import { EventBus } from "../../../event-bus";

@Component({
  components: {},
  filters: {
    rotateColor(payload: any) {
      const colors: Array<string> = [
        "primary",
        "red",
        "purple",
        "green",
        "amber",
      ];

      const getRandomInt = (max: number) => {
        return Math.floor(Math.random() * Math.floor(max));
      };

      return colors[getRandomInt(4)];
    },
    formatDecimal(payload: any) {
      const thousands_seperators = (num: any) => {
        const num_parts = num.toString().split(".");

        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return num_parts.join(".");
      };

      let total = payload;

      total = thousands_seperators(total);

      return total;
    },
  },
})
export default class SvenPool extends Mixins(NftMixin, ToolMixin) {
  cancelInterval: any = null;
  headers: Array<object> = [
    {
      text: "#",
      align: "center",
      value: "rank",
      class: "sticky-header",
    },
    { text: "Pineapples", value: "pineapples", class: "sticky-header" },
    { text: "Address", value: "address", class: "sticky-header" },
  ];
  item: number = 0;
  itemsPerPage: number = 50;
  itemsPerPageArray: number[] = [25, 50, 100];
  loading: boolean = false;
  loadMoreData: boolean = true;
  offset: number = 0;
  page: number = 1;
  search: string = "";

  //@Prop(Array) headers: any;
  //@Prop(Array) list!: Array<any>;
  @Prop(String) name!: string;

  beforeDestroy() {
    clearInterval(this.cancelInterval);
  }

  created() {
    this.loading = true;
  }

  @Watch("pools", { deep: true })
  removeLoader() {
    const match = this.pools.find((x: any) => {
      return x.name == this.name;
    });

    if (match) {
      if (match.transactions.length > 0) this.loading = false;
    }
  }

  get title(): string {
    return `${this.name} Pool`;
  }

  get items(): any {
    const match = this.pools.find((x: any) => {
      return x.name == (this.$router as any).history.current.name;
    });

    if (match) {
      match.transactions.sort(this.compare);
      match.transactions.forEach((tx: any, i: number) => {
        this.$set(tx, "rank", i + 1);
      });
      return match.transactions;
    } else {
      return undefined;
    }
  }

  get numberOfPages(): number {
    return Math.ceil(this.items.length / this.itemsPerPage);
  }

  private nextPage() {
    if (this.page + 1 <= this.numberOfPages) this.page += 1;
  }

  private formerPage() {
    if (this.page - 1 >= 1) this.page -= 1;
  }

  private updateItemsPerPage(number: number) {
    this.itemsPerPage = number;
  }

  private compare(a: any, b: any) {
    if (a.pineapples > b.pineapples) {
      return -1;
    }
    if (a.pineapples < b.pineapples) {
      return 1;
    }
    return 0;
  }
}
