import { Schema1 } from "../schemas/schema-1";

export class DiegoRodriguez extends Schema1 {
  constructor(
    assetPrice: string | number,
    name: string = "Artist Drop 3 - Diego Rodriguez",
    address: string = "0x6202e09B16c77a5EbD2C4ED99D1F9D653A02B229"
  ) {
    super(assetPrice, name, address);
    this.initialize();
  }

  private async initialize(): Promise<any> {
    const abi = await super.getAbi();

    super.getContract(abi);

    await super.totalSupply();

    await super.getPastEvents();

    await super.getPineapples();
  }
}
