














































import { Component, Mixins, Watch } from "vue-property-decorator";
import Vue from "vue";
// Mixins
import ToolMixin from "../../../mixins/accessors/tool";
import NftMixin from "../../../mixins/accessors/nft";
// Event Bus
import { EventBus } from "../../../event-bus/index";

import Axios from "../../../middleware/api";

@Component({
  components: {},
  filters: {
    formatNumber(payload: any) {
      if (payload) {
        const thousands_seperators = (num: any) => {
          const num_parts = num.toString().split(".");
          num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return num_parts.join(".");
        };
        const total = thousands_seperators(payload);

        if (payload == 0) {
          return "N/A";
        } else {
          return `$${total}`;
        }
      }
    },
  },
})
export default class MiddleBar extends Mixins(NftMixin, ToolMixin) {
  theme: boolean = this.$vuetify.theme.dark;

  get _totalNfts() {
    return this.totalNfts;
  }

  get _totalMarketCap() {
    return this.totalMarketCap;
  }

  toggleTheme(): void {
    this.$cookies.remove("darkMode");
    this.$cookies.remove("lightMode");

    this.$vuetify.theme.dark = !this.theme;
    this.theme = this.$vuetify.theme.dark;

    if (this.$vuetify.theme.dark) {
      this.$cookies.set("darkMode", this.$vuetify.theme.dark);
      this.darkTheme = true;
    } else {
      this.$cookies.set("lightMode", this.$vuetify.theme.dark);
      this.darkTheme = false;
    }
  }
}
