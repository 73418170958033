




import { Component, Prop, Vue } from "vue-property-decorator";
import DashboardComponent from "@/components/view_components/DashboardComponent.vue";
// Importing Event Bus
import { EventBus } from "../event-bus/index";

@Component({
  components: {
    DashboardComponent,
  },
})
export default class Dashboard extends Vue {}
