
























































// Vue property decorator
import {
  Component,
  Prop,
  Emit,
  Vue,
  Mixins,
  Watch,
} from "vue-property-decorator";
// Mixins
import ToolMixin from "../../../mixins/accessors/tool";
import NftMixin from "../../../mixins/accessors/nft";
// Template
import BaseTemplate from "../template/index.vue";
// Axios
import axios from "axios";
import Axios from "../../../middleware/api";
// EventBus
import { EventBus } from "../../../event-bus";

@Component({
  components: {
    BaseTemplate,
  },
  filters: {
    rotateColor(payload: any) {
      const colors: Array<string> = [
        "primary",
        "red",
        "purple",
        "green",
        "amber",
      ];

      const getRandomInt = (max: number) => {
        return Math.floor(Math.random() * Math.floor(max));
      };

      return colors[getRandomInt(4)];
    },
    formatNumber(payload: any) {
      if (payload) {
        const thousands_seperators = (num: any) => {
          const num_parts = num.toString().split(".");
          num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return num_parts.join(".");
        };
        const total = thousands_seperators(payload);
        console.log("format num", payload);
        if (payload == 0) {
          return "N/A";
        } else {
          return `$${total}`;
        }
      }
    },

    formatDecimal(payload: any) {
      console.log(payload, "format decimal");
      const thousands_seperators = (num: any) => {
        const num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return num_parts.join(".");
      };

      const total = thousands_seperators(payload);

      return total;
    },

    formatDecimal2(payload: any) {
      const thousands_seperators = (num: any) => {
        const num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return num_parts.join(".");
      };
      let total = (Math.round(Number(payload) * 100) / 100).toFixed(2);
      total = thousands_seperators(total);
      console.log("form 2", payload);
      if (total == undefined) {
        return "N/A";
      } else {
        return `$${total}`;
      }
    },
  },
})
export default class DecentralandWearables extends Mixins(NftMixin, ToolMixin) {
  CancelToken = axios.CancelToken;
  source = this.CancelToken.source();

  cancelToken: any;

  bidList: Array<any> = [];
  cancelInterval: any = null;
  headers: Array<object> = [
    {
      text: "#",
      align: "center",
      value: "token_rank",
    },
    {
      text: "Asset",
      align: "center",
      value: "image_thumbnail_url",
    },
    { text: "Title", value: "name" },
    { text: "Last", value: "last_sale_usd" },
    { text: "Bid", value: "bid" },
    { text: "Ask", value: "ask" },
    { text: "Max Supply", value: "max_supply" },
    { text: "Market Cap", value: "market_cap" },
    {
      text: "",
      value: "action",
      sortable: false,
    },
  ];
  imageUrl: string = "";
  item: number = 0;
  items: Array<any> = [];
  itemsPerPage: number = 50;
  itemsPerPageArray: number[] = [25, 50, 100];
  loading: boolean = false;
  loadMoreData: boolean = true;
  offset: number = 0;
  page: number = 1;
  polledItems: Array<any> = [];
  search: string = "";
  title: string = "Top NFTs by Market Capitalization";

  async beforeDestroy() {
    clearInterval(this.cancelInterval);
    await this.source.cancel("Operation cancelled");
    this.offset = 0;
  }

  created() {
    this.loading = true;
    this.getTokenData();
  }

  get numberOfPages(): number {
    return Math.ceil(this.items.length / this.itemsPerPage);
  }

  get totalMarketCap(): string | number {
    const total = this.items.reduce((prev, cur) => prev + cur.market_cap, 0);
    return total;
  }

  private marketCap(payload: any, last: any): any {
    if (payload.last_sale) {
      // const decimals: number = payload.last_sale.payment_token.decimals;
      // let total: number = payload.last_sale.total_price;
      // const usd: number = payload.last_sale.payment_token.usd_price;
      let marketCap: number;

      // total /= Math.pow(10, decimals);

      const match = payload.traits.find(
        (x: any) => x.trait_type == "Max Supply"
      );

      if (match) {
        marketCap = last * match.value;

        return Math.ceil(marketCap);
      }
    } else {
      return -1;
    }
  }

  private lastSale(payload: any): any {
    if (payload.last_sale) {
      const quantity: number = payload.last_sale.quantity;
      const decimals: number = payload.last_sale.payment_token.decimals;
      let total: any = payload.last_sale.total_price;
      const usd: number = payload.last_sale.payment_token.usd_price;

      const thousands_seperators = (num: any) => {
        const num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return num_parts.join(".");
      };

      total /= Math.pow(10, decimals);
      total = (total * usd) / quantity;
      console.log("last sale", total);
      return total;
    } else {
      console.log("last sale else", 0);
      return 0;
    }
  }

  private ask(payload: any): any {
    if (payload.sell_orders[0]) {
      const quantity: number =
        payload.sell_orders[0].metadata.asset.quantity ||
        payload.sell_orders[0].quantity;
      const decimals: number =
        payload.sell_orders[0].payment_token_contract.decimals;
      let total: number = payload.sell_orders[0].current_price;
      const usd: number =
        payload.sell_orders[0].payment_token_contract.usd_price;

      total /= Math.pow(10, decimals);
      total = (total * usd) / quantity;
      // console.log('ask', total)
      return total;
    } else return 0;
  }

  private compare(a: any, b: any) {
    if (a.market_cap > b.market_cap) {
      return -1;
    }
    if (a.market_cap < b.market_cap) {
      return 1;
    }
    return 0;
  }

  private maxSupply(payload: any): any {
    const match = payload.traits.find((x: any) => x.trait_type == "Max Supply");

    if (match) {
      return match.value;
    }
  }

  private async getTokenData(): Promise<any> {
    const processResponse = (response: any) => {
      response.assets.forEach((el: any) => {
        el["bid"] = "";
        el["last_sale_usd"] = this.lastSale(el);
        el["market_cap"] = this.marketCap(el, el["last_sale_usd"]);
        el["max_supply"] = this.maxSupply(el);
        el["ask"] = this.ask(el);
        this.items.push(el);
      });

      this.items.sort(this.compare);

      if (response.assets.length == 50) {
        this.apiOffset = this.apiOffset + 50;
        this.getTokenData();
      } else {
        this.loadMoreData = false;
        this.apiOffset = 0;
        this.items.forEach((el: any, i: number) => {
          el["token_rank"] = i + 1;
        });
      }
      this.loading = false;
      //   this.getBid();
    };

    const request = async () => {
      try {
        const response = await Axios.getCollections(
          this.apiOffset,
          this.source
        );
        processResponse(response.data);
      } catch (error) {
        this.loading = false;
        if (error) throw error;
      }
    };

    if (this.loadMoreData) request();
  }

  private async getBid(): Promise<any> {
    const ids: Array<any> = [];

    this.items.forEach((el: any) => {
      const param: string = `token_ids=${el.token_id}`;
      ids.push(param);
    });

    const uriParam: string = ids.toString().replace(/,/g, "&");

    const processResponse = (response: any) => {
      response.orders.forEach((x: any) => this.bidList.push(x));

      if (response.orders.length == 50) {
        this.offset = this.offset + 50;
        this.getBid();
      } else {
        this.items.forEach((el: any) => {
          const list: Array<any> = this.bidList.filter(
            (x: any) => x.asset.token_id == el.token_id
          );
          if (list.length > 0) {
            const decimals = list[0].payment_token_contract.decimals;
            let bid = list[0].base_price;
            bid /= Math.pow(10, decimals);
            const total =
              (bid * list[0].payment_token_contract.usd_price) /
              list[0].quantity;
            this.$set(el, "bid", total);
          }
        });
        this.offset = 0;
        this.bidList.splice(0, this.bidList.length);
      }
    };

    try {
      const response = await Axios.getBid(uriParam, this.offset);
      processResponse(response.data);
    } catch (error) {
      if (error.response) throw error;
    }
  }

  private nextPage() {
    if (this.page + 1 <= this.numberOfPages) this.page += 1;
  }

  private formerPage() {
    if (this.page - 1 >= 1) this.page -= 1;
  }

  private updateItemsPerPage(number: number) {
    this.itemsPerPage = number;
  }

  private renderFullImage(url: string): void {
    this.imageDialog = true;
    this.imageUrl = url;
  }
}
