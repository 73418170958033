import { Genesis } from "../genesis/genesis";
import { Vans } from "../artist-drop/vans";
import { Sven } from "../artist-drop/sven";
import { BigComicArt } from "../artist-drop/big-comic-art";
import { DiegoRodriguez } from "../artist-drop/diego-rodriguez";
import { Jon } from "../artist-drop/jon";
import { Spongenuity } from "../artist-drop/spongenuity";
import { TheBlockTimes } from "../artist-drop/the-block-times";
import { Wolfe } from "../artist-drop/jonathan-wolfe";
import { Fewocious } from "../artist-drop/fewocious";
import { PrimeDao } from "../collections/prime-dao";
import { Labs } from "../event-drop/labs";
import { BadgerDao } from "../featured-drop/badger-dao";
import { LpBadgerDao } from "../featured-drop/lp-badger-dao";
import { Yop } from "../featured-drop/yop";
import { Digg } from "../featured-drop/digg";
import { LpGenesis } from "../lp-genesis/lp-genesis";
import { BitcoinOrigins } from "../featured-drop/bitcoin-origins";
import { TerraVirtua } from "../featured-drop/terra-virtua";
import { Aymeric } from "../artist-drop/aymeric";
import { WizardSkull } from "../artist-drop/wizard-skull";
import { Cometh } from "../featured-drop/cometh";
import { Trippyogi } from "../artist-drop/trippyogi";
import { TyeDied } from "../artist-drop/tye-died";
import { Sinclair } from "../artist-drop/sinclair";
import { FrankWilder } from "../artist-drop/frank-wilder";
import { ExitSimulation } from "../artist-drop/exitsimulation";
import { Zeroxb1Collection } from "../artist-drop/0xb1-collection";
import { Remo } from "../artist-drop/remo";
import { Odious } from "../artist-drop/odious";
import { NibbleBits } from "../artist-drop/nibble-bits";
import { VansDesign } from "../artist-drop/vans-design";
import { Dutchtide } from "../artist-drop/dutchtide";
import { GraffitiKings } from "../artist-drop/graffiti-kings";
import { KittyBast } from "../artist-drop/kitty-bast";
import { AmritPalSingh } from "../artist-drop/amrit-pal-singh";
import { Lushsux } from "../artist-drop/lushsux";
import { RafGrassetti } from "../artist-drop/raf-grassetti";
import { WoodenCyclops } from "../artist-drop/wooden-cyclops";
import { BlockchainHeros } from "../artist-drop/blockchain-heros";
import { JCRivera } from "../artist-drop/jc-rivera";
import { HollowDaDon } from "../artist-drop/hollow-da-don";
import { TylerRusso } from "../artist-drop/tyler-russo";
import { TheGremlins } from "../artist-drop/the-gremlins";
import { Junkyard } from "../artist-drop/junkyard";
import { ChrisPotter } from "../artist-drop/chris-potter";
import { DominicGlover } from "../artist-drop/dominic-glover";
import { Nahiko } from "../artist-drop/nahiko";
import { KristyGlas } from "../artist-drop/kristy-glas";
import { TwerkyClub } from "../artist-drop/twerky-club";
import { Emotionull } from "../artist-drop/emotionull";
import { LucasGuzman } from "../artist-drop/lucas-guzman";
import { JamesMcdermott } from "../artist-drop/james-mcdermott";
import { Mafriends } from "../artist-drop/mafriends";
import { PolychainMonsters } from "../featured-drop/polychain-monsters";

// Use ES6 Object Literal Property Value Shorthand to maintain a map
// where the keys share the same names as the classes themselves
const classes: any = {
  PolychainMonsters,
  Mafriends,
  JamesMcdermott,
  LucasGuzman,
  Emotionull,
  TwerkyClub,
  KristyGlas,
  Nahiko,
  DominicGlover,
  ChrisPotter,
  Junkyard,
  TheGremlins,
  TylerRusso,
  HollowDaDon,
  JCRivera,
  Lushsux,
  RafGrassetti,
  WoodenCyclops,
  BlockchainHeros,
  AmritPalSingh,
  KittyBast,
  GraffitiKings,
  Dutchtide,
  VansDesign,
  NibbleBits,
  Odious,
  Digg,
  Remo,
  Zeroxb1Collection,
  ExitSimulation,
  FrankWilder,
  Sinclair,
  TyeDied,
  Trippyogi,
  Cometh,		
  WizardSkull,	
  Aymeric,
  TerraVirtua,
  Genesis,
  Vans,
  Sven,
  BigComicArt,
  DiegoRodriguez,
  Jon,
  Labs,
  PrimeDao,
  Spongenuity,
  TheBlockTimes,
  BadgerDao,
  Yop,
  LpGenesis,
  LpBadgerDao,
  BitcoinOrigins,
  Wolfe,
  Fewocious
};

class DynamicClass {
  constructor(className: any, opts: any) {
    return new classes[className](opts);
  }
}

export default DynamicClass;
