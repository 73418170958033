import { Schema1 } from "../schemas/schema-1";

export class Labs extends Schema1 {
  constructor(
    assetPrice: string | number,
    name: string = "Event Drop 0 - LABS",
    address: string = "0xe1D7c67221d414D95cB5A9089bA364cC17a9eaB3"
  ) {
    super(assetPrice, name, address);
    this.initialize();
  }

  private async initialize(): Promise<any> {
    const abi = await super.getAbi();

    super.getContract(abi);

    await super.totalSupply();

    await super.getPastEvents();

    await super.getPineapples();
  }
}
