













import { Component, Prop, Mixins, Vue, Watch } from "vue-property-decorator";

import Notifications from "@/components/notifications/index.vue";
import NftMixin from "./mixins/accessors/nft";
import ToolMixin from "./mixins/accessors/tool";
import Sidebar from "@/components/sidebar/index.vue";

// Axios
import Axios from "./middleware/api";
import axios from "axios";

import { EventBus } from "./event-bus/index";

import { ethers } from "ethers";
import Web3 from "web3";
import BigNumber from "bignumber.js";

import { ChainId, Token, WETH, Fetcher, Route } from "@uniswap/sdk";

import DynamicClass from "./classes/pools/master/dynamic";

const ethEnabled = () => {
  if ((window as any).ethereum) {
    (window as any).web3 = new Web3((window as any).ethereum);
    (window as any).ethereum.enable();
    return true;
  }
  return false;
};

const eth = ethEnabled();

const infura = new Web3.providers.HttpProvider(
  "https://mainnet.infura.io/v3/e1a2634a66fc4f4c816926b47ae06164"
  // "https://eth-mainnet.alchemyapi.io/v2/MHKoTXWvg8_Bnp87K8YuMmlN6tKf_heP"
  // "http://127.0.0.1:8545"
);

const provider = new ethers.providers.JsonRpcProvider(
  "https://mainnet.infura.io/v3/e1a2634a66fc4f4c816926b47ae06164"
);

const web3 = new Web3(infura);

@Component({
  components: {
    Notifications,
    Sidebar,
  },
})
export default class App extends Mixins(NftMixin, ToolMixin) {
  counter: number = 0;
  _offset: number = 0;
  poolNumber: number = 0;
  promisePool: Array<any> = [];
  poolTotalSupply: number = 0;
  totalSupplyActive: boolean = true;
  txNumber: number = 0;
  transactionCounter: number = 0;
  ethEnabled: boolean = false;
  weth: any = undefined;
  memePair: any = undefined;
  badgerPair: any = undefined;
  yopPair: any = undefined;
  diggPair: any = undefined;
  removeMargin: boolean = false;

  test: Array<any> = [];

  async created() {
    EventBus.$on("UNLOCK_WALLET", () => this.unlockWallet());
    EventBus.$on("TOGGLE_MARGIN", () => this.updateMargin());

    this.checkWallet();

    await this.getPrice();

    console.log("Meme price: $", this.memePrice);
    console.log("Badger price: $", this.badgerPrice);
	console.log("Yop price: $", this.yopPrice);
	console.log("Digg price: $", this.diggPrice);

    const pools = [
      { pool: "PolychainMonsters", args: this.memePrice },
      { pool: "Mafriends", args: this.memePrice },
      { pool: "JamesMcdermott", args: this.memePrice },
      { pool: "LucasGuzman", args: this.memePrice },
      { pool: "Emotionull", args: this.memePrice },
      { pool: "TwerkyClub", args: this.memePrice },
      { pool: "KristyGlas", args: this.memePrice },
      { pool: "Nahiko", args: this.memePrice },
      { pool: "DominicGlover", args: this.memePrice },
      { pool: "ChrisPotter", args: this.memePrice },
      { pool: "Junkyard", args: this.memePrice },
      { pool: "TheGremlins", args: this.memePrice },
      { pool: "TylerRusso", args: this.memePrice },
      { pool: "HollowDaDon", args: this.memePrice },
      { pool: "JCRivera", args: this.memePrice },
      { pool: "Lushsux", args: this.memePrice },
      { pool: "RafGrassetti", args: this.memePrice },
      { pool: "WoodenCyclops", args: this.memePrice },
      { pool: "BlockchainHeros", args: this.memePrice },
      { pool: "AmritPalSingh", args: this.memePrice },
      { pool: "KittyBast", args: this.memePrice },
      { pool: "GraffitiKings", args: this.memePrice },
      { pool: "Dutchtide", args: this.memePrice },
      { pool: "VansDesign", args: this.memePrice },
      { pool: "NibbleBits", args: this.memePrice },
      { pool: "Odious", args: this.memePrice },
      { pool: "Digg", args: this.diggPrice },
      { pool: "Remo", args: this.memePrice },
      { pool: "Zeroxb1Collection", args: this.memePrice },
      { pool: "ExitSimulation", args: this.memePrice },
      { pool: "FrankWilder", args: this.memePrice },
      { pool: "Sinclair", args: this.memePrice },
      { pool: "TyeDied", args: this.memePrice },
      { pool: "Trippyogi", args: this.memePrice },
      { pool: "Aymeric", args: this.memePrice },
      { pool: "WizardSkull", args: this.memePrice },
      { pool: "Cometh", args: this.memePrice },
      { pool: "Wolfe", args: this.memePrice },
      { pool: "Fewocious", args: this.memePrice },
      { pool: "Jon", args: this.memePrice },
      { pool: "Vans", args: this.memePrice },
      { pool: "Sven", args: this.memePrice },
      { pool: "BigComicArt", args: this.memePrice },
      { pool: "DiegoRodriguez", args: this.memePrice },
      { pool: "PrimeDao", args: this.memePrice },
      { pool: "TheBlockTimes", args: this.memePrice },
      { pool: "Spongenuity", args: this.memePrice },
      { pool: "BitcoinOrigins", args: this.memePrice },
      { pool: "TerraVirtua", args: this.memePrice },
      { pool: "Yop", args: this.yopPrice },
      { pool: "Labs", args: this.memePrice },
      { pool: "Genesis", args: this.memePrice },
      { pool: "LpGenesis", args: this.memePrice },
      { pool: "BadgerDao", args: this.badgerPrice },
      { pool: "LpBadgerDao", args: this.badgerPrice },
    ];

    pools.forEach((x, i) => {
      setTimeout(() => {
        this.pools.push(new DynamicClass(x.pool, x.args));
      }, i * 900);
    });

    this.lightOrDark();
  }

  private checkWallet(): void {
    if (this.$cookies.isKey("wallet")) {
      this.unlockWallet();
    }
  }

  lightOrDark(): void {
    if (this.$cookies.isKey("darkMode")) {
      this.$vuetify.theme.dark = true;
      this.$cookies.set("darkMode", this.$vuetify.theme.dark);
      this.darkTheme = true;
    } else {
      this.$vuetify.theme.dark = false;
      this.$cookies.set("lightMode", this.$vuetify.theme.dark);
      this.darkTheme = false;
    }
  }

  private async getPrice(): Promise<any> {
    const price = async (): Promise<any> => {
      const chainId = ChainId.MAINNET;
      const daiTokenAddress = "0x6B175474E89094C44Da98b954EedeAC495271d0F"; // must be checksummed
      const memeTokenAddress = "0xD5525D397898e5502075Ea5E830d8914f6F0affe"; // must be checksummed

      const badgerTokenAddress = "0x3472A5A71965499acd81997a54BBA8D852C6E53d";
      const badgerDecimals = 18;

      const yopTokenAddress = "0xAE1eaAE3F627AAca434127644371b67B18444051";
      const yopDecimals = 8;

      const diggTokenAddress = "0x798d1be841a82a273720ce31c822c61a67a601c3";
      const diggDecimals = 9;

      const daiDecimals = 18;
      const memeDecimals = 8;

      const DAI = new Token(chainId, daiTokenAddress, daiDecimals);

      const MEME = new Token(chainId, memeTokenAddress, memeDecimals);

      const BADGER = new Token(chainId, badgerTokenAddress, badgerDecimals);

      const YOP = new Token(chainId, yopTokenAddress, yopDecimals);

      const DIGG = new Token(chainId, diggTokenAddress, diggDecimals);

      const pair = await Fetcher.fetchPairData(
        DAI,
        WETH[DAI.chainId],
        provider
      );
      const memePair = await Fetcher.fetchPairData(
        MEME,
        WETH[MEME.chainId],
        provider
      );
      const badgerPair = await Fetcher.fetchPairData(
        BADGER,
        WETH[BADGER.chainId],
        provider
      );
      const yopPair = await Fetcher.fetchPairData(
        YOP,
        WETH[YOP.chainId],
        provider
      );
      const diggPair = await Fetcher.fetchPairData(
        DIGG,
        WETH[DIGG.chainId],
        provider
      );

      const route = new Route([pair], WETH[DAI.chainId]);
      const _route = new Route([memePair], WETH[MEME.chainId]);
      const badgerRoute = new Route([badgerPair], WETH[BADGER.chainId]);
      const yopRoute = new Route([yopPair], WETH[YOP.chainId]);
      const diggRoute = new Route([diggPair], WETH[DIGG.chainId]);

      this.weth = route.midPrice.toSignificant(6);
      this.memePair = _route.midPrice.toSignificant(6);
      this.badgerPair = badgerRoute.midPrice.toSignificant(6);
      this.yopPair = yopRoute.midPrice.toSignificant(6);
      this.diggPair = diggRoute.midPrice.toSignificant(6);

      this.badgerPrice = this.weth / this.badgerPair;

      this.yopPrice = this.weth / this.yopPair;

      this.diggPrice = this.weth / this.diggPair;

      this.memePrice = this.weth / this.memePair;
    };

    const promise = new Promise<any>((res: any, rej: any) => {
      const req = async (_res: any, _rej: any): Promise<any> => {
        try {
          await price();

          return res();
        } catch (error) {
          if (error) return rej;
        }
      };
      return req(res, rej);
    });

    return promise;
  }

  private async unlockWallet(): Promise<any> {
    if ((window as any).ethereum) {
      (window as any).web3 = new Web3((window as any).ethereum);
      try {
        // Request account access if needed
        await (window as any).ethereum.enable();
        // Acccounts now exposed
        const result = await web3.eth.getBalance(
          web3.givenProvider.selectedAddress
        );

        const res = await web3.eth.accounts;

        this.myWallet = web3.givenProvider.selectedAddress;

        this.getWalletAbi();
        web3.eth.isMining((err, res) => {
          console.log(res, err);
        });
      } catch (error) {
        // User denied account access...
      }
      // console.log(web3.givenProvider.selectedAddress);
    }
    // Legacy dapp browsers...
    else if ((window as any).web3) {
      (window as any).web3 = new Web3(web3.currentProvider);
      // Acccounts always exposed
      const result = await web3.eth.getBalance(
        web3.givenProvider.selectedAddress
      );
    }
    // Non-dapp browsers...
    else {
      console.log(
        "Non-Ethereum browser detected. You should consider trying MetaMask!"
      );
    }
  }

  private async getCollection(_offset: number = 0): Promise<any> {
    let offset: number = _offset;

    const process = (response: any) => {
      response.assets.forEach((asset: any) => {
        asset["last_sale_usd"] = this.lastSale(asset);
        this.myCollection.push(asset);
      });

      if (response.assets.length == 20) {
        offset += 20;
        this.getCollection(offset);
      } else {
        this.nftTotalSupply();
        this.unlocked = true;
        offset = 0;
      }
    };

    const req = async (): Promise<any> => {
      try {
        const res = await Axios.getMyCollection(offset, this.myWallet);
        process(res.data);
      } catch (error) {
        if (error) throw error;
      }
    };

    req();
  }

  private lastSale(payload: any): any {
    if (payload.last_sale) {
      const quantity: number = payload.last_sale.quantity;
      const decimals: number = payload.last_sale.payment_token.decimals;
      let total: any = payload.last_sale.total_price;
      const usd: number = payload.last_sale.payment_token.usd_price;

      const thousands_seperators = (num: any) => {
        const num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return num_parts.join(".");
      };

      total /= Math.pow(10, decimals);
      total = (total * usd) / quantity;

      return total;
    } else {
      return 0;
    }
  }

  private async getWalletAbi(): Promise<any> {
    this.myCollection.splice(0, this.myCollection.length);
    this.$cookies.set("wallet", true);
    const process = (response: any) => {
      this.$set(this.memeLtdContract, "abi", response.data.result);
      this.getWalletContract();
    };

    try {
      const res = await Axios.getAbi(this.memeLtdContract.address);

      process(res);
    } catch (error) {
      if (error) throw error;
    }
  }

  private getWalletContract(): void {
    const contract = new web3.eth.Contract(
      JSON.parse(this.memeLtdContract.abi),
      this.memeLtdContract.address
    );

    this.$set(this.memeLtdContract, "contract", contract);

    this.getCollection();
  }

  private async nftTotalSupply() {
    const supply = async (_total: any, id: any) => {
      const total = _total;

      const match = this.myCollection.find((x: any) => {
        return x.token_id == id;
      });
      if (match) {
        this.$set(match, "total_quantity", total);
        const total_value = match["last_sale_usd"] * match["total_quantity"];
        this.$set(match, "total_value", total_value);
      }
    };

    const process = async (list: Array<any>): Promise<any> => {
      const batch = new web3.BatchRequest();

      const makeBatchRequest = (calls: any) => {
        const promises = calls.map((call: any) => {
          return new Promise((_res, rej) => {
            const callback = (err: any, res: any) => {
              if (err) rej(err);
              else
                _res({
                  amount: res,
                  token_id: call.token_id,
                });
            };

            const req = call.web3Method.request(callback);

            batch.add(req);
          });
        });

        batch.execute();

        return Promise.all(promises);
      };

      const asyncData = await makeBatchRequest(list);

      asyncData.forEach((r: any) => {
        supply(r.amount, r.token_id);
      });

      // this.transactions();
    };

    const _batch = this.myCollection.map((x: any) => {
      const reqObj: any = {
        web3Method: this.memeLtdContract.contract.methods.balanceOf(
          this.myWallet,
          x.token_id
        ).call,
        token_id: x.token_id,
      };
      return reqObj;
    });

    process(_batch.flat());
  }

  private updateMargin(): void {
    setTimeout(() => {
      this.removeMargin = true;
    }, 400);
  }
}
