import { render, staticRenderFns } from "./vans.vue?vue&type=template&id=616ace80&scoped=true&"
import script from "./vans.vue?vue&type=script&lang=ts&"
export * from "./vans.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "616ace80",
  null
  
)

export default component.exports