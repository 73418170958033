import { render, staticRenderFns } from "./theBlockTimes.vue?vue&type=template&id=38426141&scoped=true&"
import script from "./theBlockTimes.vue?vue&type=script&lang=ts&"
export * from "./theBlockTimes.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "38426141",
  null
  
)

export default component.exports