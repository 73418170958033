





















import { Component, Mixins, Watch } from "vue-property-decorator";
import Vue from "vue";
// Mixins
import ToolMixin from "../../../mixins/accessors/tool";
import NftMixin from "../../../mixins/accessors/nft";
// Event Bus
import { EventBus } from "../../../event-bus/index";

import Axios from "../../../middleware/api";

@Component({
  components: {},
})
export default class TopBar extends Mixins(NftMixin, ToolMixin) {
  display: boolean = true;
  active: boolean = true;

  @Watch("display")
  fadeOut(): void {
    if (!this.display) {
      setTimeout(() => (this.active = false), 400);
    }
  }

  private remove(): void {
    this.display = false;
    EventBus.$emit("TOGGLE_MARGIN");
  }
}
