




// Vue property decorator
import {
  Component,
  Prop,
  Emit,
  Vue,
  Mixins,
  Watch,
} from "vue-property-decorator";
// Mixins
import ToolMixin from "../../../mixins/accessors/tool";
import NftMixin from "../../../mixins/accessors/nft";
// Axios
import Axios from "../../../middleware/api";
// EventBus
import { EventBus } from "../../../event-bus";

import BaseTemplate from "../template/index.vue";

@Component({
  components: { BaseTemplate },
})
export default class FewociousPool extends Mixins(NftMixin, ToolMixin) {
  name: string = "Artist Drop 8 - Fewocious";
}
