import { ToolState } from "@/interfaces";

const initialState = (): ToolState => {
  return {
    apiOffset: 0,
    contentProgress: false,
    darkTheme: false,
    dataSearch: "",
    imageDialog: false,
    sideNav: false,
    unlocked: false,
  };
};
export const tool = {
  namespaced: true,
  state: initialState,

  mutations: {

    UPDATE_CONTENT_PROGRESS(state: any, payload: boolean) {
      state.contentProgress = payload;
    },
    UPDATE_DARK_THEME(state: any, payload: boolean) {
      state.darkTheme = payload;
    },
    UPDATE_DATA_SEARCH(state: any, payload: string) {
      state.dataSearch = payload;
    },
    UPDATE_IMAGE_DIALOG(state: any, payload: boolean) {
      state.imageDialog = payload;
    },
    UPDATE_API_OFFSET(state: any, payload: number) {
      state.apiOffset = payload;
    },
    UPDATE_SIDE_NAV(state: any, payload: number) {
      state.sideNav = payload;
    },
    UPDATE_UNLOCKED(state: any, payload: boolean) {
      state.unlocked = payload;
    },
    RESET_STATE(state: ToolState) {
      const clonedState: ToolState = initialState();

      Object.keys(clonedState).forEach((key) => {
        state[key] = clonedState[key];
      });
    },
  },

};