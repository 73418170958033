var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('BaseTemplate',{attrs:{"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"imageUrl":_vm.imageUrl},scopedSlots:_vm._u([{key:"title",fn:function(){return [_vm._v(" "+_vm._s(_vm.title)+" ")]},proxy:true},{key:"rank",fn:function(ref){
var item = ref.item;
return [(!item.token_rank)?_c('span',[_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm._f("rotateColor")('grey')}})],1):_c('span',[_vm._v(" "+_vm._s(item.token_rank)+" ")])]}},{key:"thumbnail",fn:function(ref){
var item = ref.item;
return [_c('v-img',{staticClass:"grey lighten-2 mt-2 mb-2 imageHover",attrs:{"src":item.image_thumbnail_url,"aspect-ratio":6 / 9,"width":_vm.$vuetify.breakpoint.smAndDown ? 49 : 70},on:{"click":function($event){return _vm.renderFullImage(item.image_original_url)}},scopedSlots:_vm._u([{key:"placeholder",fn:function(){return [_c('v-row',{staticClass:"fill-height ma-0",attrs:{"align":"center","justify":"center"}},[_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm._f("rotateColor")('grey')}})],1)]},proxy:true}],null,true)})]}},{key:"lastSale",fn:function(ref){
var item = ref.item;
return [(item.last_sale_usd)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDecimal2")(item.last_sale_usd))+" ")]):_c('span',[_vm._v(" N/A ")])]}},{key:"bid",fn:function(ref){
var item = ref.item;
return [(item.bid == '')?_c('span',[_vm._v(" N/A ")]):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDecimal2")(item.bid))+" ")])]}},{key:"ask",fn:function(ref){
var item = ref.item;
return [(item.ask)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDecimal2")(item.ask))+" ")]):_c('span',[_vm._v(" N/A ")])]}},{key:"maxSupply",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("formatDecimal")(item.max_supply))+" ")]}},{key:"market",fn:function(ref){
var item = ref.item;
return [(item.market_cap && item.market_cap !== -1)?_c('span',[_vm._v(" "+_vm._s(_vm._f("formatNumber")(item.market_cap))+" ")]):(item.market_cap == -1)?_c('span',[_vm._v(" N/A ")]):_vm._e()]}},{key:"action",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{staticClass:"white--text smallText text-capitalize",attrs:{"color":"#6600cc","href":((item.permalink) + "?ref=0x30009765D2BFD320931F5B29Fc5523BfabEEb3D5"),"target":"_blank"}},[_c('img',{staticClass:"mr-2 white--text",attrs:{"height":"30px","color":"red","src":require("../../../assets/open-sea-logo.svg")}}),_vm._v(" OpenSea ")])]}}],null,true)})}
var staticRenderFns = []

export { render, staticRenderFns }