import BigNumber from "bignumber.js";

const helpers = {
  applyDecimals: (value: any, decimals: any): any => {
    const b = new BigNumber(value);

    return b
      .div(Math.pow(10, decimals))
      .decimalPlaces(decimals)
      .toNumber();
  },
  removeDuplicates: (transactions: any, name: string) => {
    return [
      ...new Map(
        transactions.map((transaction: any) => [
          transaction.returnValues.user,
          {
            address: transaction.returnValues.user,
            rank: undefined,
            totalLockedValue: undefined,
            totalValue: undefined,
            pineapples: undefined,
            name: name,
          },
        ])
      ).values(),
    ];
  },
};

export default helpers;
