

















































































































































// Vue property decorator
import {
  Component,
  Prop,
  Emit,
  Vue,
  Mixins,
  Watch,
} from "vue-property-decorator";
// Mixins
import ToolMixin from "../../mixins/accessors/tool";
import NftMixin from "../../mixins/accessors/nft";
// Axios
import Axios from "../../middleware/api";
// EventBus
import { EventBus } from "../../event-bus";
// import web3 from '../contracts/web3.js';
import Web3 from "web3";
import BigNumber from "bignumber.js";

// const web3 = new Web3(
new Web3.providers.HttpProvider(
  "https://mainnet.infura.io/v3/e1a2634a66fc4f4c816926b47ae06164"
);
// );
const web3 = new Web3(Web3.givenProvider);

@Component({
  components: {},
  filters: {
    rotateColor(payload: any) {
      const colors: Array<string> = [
        "primary",
        "red",
        "purple",
        "green",
        "amber",
      ];

      const getRandomInt = (max: number) => {
        return Math.floor(Math.random() * Math.floor(max));
      };

      return colors[getRandomInt(4)];
    },
    formatNumber(payload: any) {
      if (payload) {
        const thousands_seperators = (num: any) => {
          const num_parts = num.toString().split(".");
          num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");
          return num_parts.join(".");
        };
        const total = thousands_seperators(payload);

        if (payload == 0) {
          return "N/A";
        } else {
          return `$${total}`;
        }
      }
    },

    formatDecimal(payload: any) {
      const thousands_seperators = (num: any) => {
        const num_parts = num.toString().split(".");
        num_parts[0] = num_parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",");

        return num_parts.join(".");
      };
      let total = (Math.round(Number(payload) * 100) / 100).toFixed(2);

      total = thousands_seperators(total);

      return total;
    },
  },
})
export default class MemeFarms extends Mixins(NftMixin, ToolMixin) {
  cancelInterval: any = null;
  componentKey = 0;
  headers: Array<object> = [
    {
      text: "#",
      align: "center",
      value: "rank",
      class: "sticky-header",
    },
    {
      text: "Name",
      align: "center",
      value: "name",
      class: "sticky-header",
    },
    { text: "Address", value: "address", class: "sticky-header" },
    { text: "Total", value: "totalValue", class: "sticky-header" },
    { text: "Total Pineapples", value: "pineapples", class: "sticky-header" },
    {
      text: "Total Value Locked",
      value: "totalLockedValue",
      class: "sticky-header",
    },

    {
      text: "",
      value: "action",
      sortable: false,
      class: "sticky-header",
    },
  ];
  item: number = 0;
  itemsPerPage: number = 50;
  itemsPerPageArray: number[] = [25, 50, 100];
  loading: boolean = false;
  loadMoreData: boolean = true;
  offset: number = 0;
  page: number = 1;
  search: string = "";
  title: string = "Top MEME Farms by Total Value Locked";

  beforeDestroy() {
    clearInterval(this.cancelInterval);
  }

  get items(): Array<any> {
    this.pools.forEach((pool: any, i: number) => {
      this.$set(pool, "rank", i + 1);
    });

    return this.pools;
  }

  created() {
    EventBus.$emit("UPDATE_TITLE", "Top MEME Farms by Total Value Locked");

    setInterval(() => {
      this.$forceUpdate();

      this.pools.sort(this.compare);
      this.pools.forEach((pool: any, i: number) => {
        this.$set(pool, "rank", i + 1);
      });
    }, 3000);
  }

  get totalMemeValue(): number {
    const filteredPools: Array<any> = this.pools.filter(
      (x: any) => x.assetType == "MEME"
    );

    const total: number = filteredPools.reduce((prev: any, cur: any) => {
      if (cur.totalValue) {
        return new BigNumber(cur.totalValue).plus(prev).toFixed(2);
      } else {
        return prev;
      }
    }, 0);

    return total;
  }

  get totalOtherValue(): number {
    const filteredPools: Array<any> = this.pools.filter(
      (x: any) => x.assetType != "MEME"
    );
    const total: number = filteredPools.reduce((prev: any, cur: any) => {
      if (cur.totalValue) {
        return new BigNumber(cur.totalValue).plus(prev).toFixed(2);
      } else {
        return prev;
      }
    }, 0);

    return total;
  }

  get lockedMemeValue(): any {
    return new BigNumber(this.totalMemeValue)
      .multipliedBy(this.memePrice)
      .toFixed(2);
  }

  get otherLockedValue(): any {
    return new BigNumber(this.totalOtherValue)
      .multipliedBy(this.badgerPrice)
      .toFixed(2);
  }

  get numberOfPages(): number {
    return Math.ceil(this.items.length / this.itemsPerPage);
  }

  private nextPage() {
    if (this.page + 1 <= this.numberOfPages) this.page += 1;
  }

  private formerPage() {
    if (this.page - 1 >= 1) this.page -= 1;
  }

  private updateItemsPerPage(number: number) {
    this.itemsPerPage = number;
  }

  private compare(a: any, b: any) {
    if (a.totalLockedValue > b.totalLockedValue) {
      return -1;
    }
    if (a.totalLockedValue < b.totalLockedValue) {
      return 1;
    }
  }

  route(item: any): void {
    EventBus.$emit("UPDATE_TITLE", item.name);
    this.$router.push({ name: item.name });
  }
}
