

















































































































































import { Component, Mixins, Watch } from "vue-property-decorator";
import Vue from "vue";

import TopBar from "../../navigator/top/index.vue";
import MiddleBar from "../../navigator/middle/index.vue";
import Search from "../../navigator/search/index.vue";
// Mixins
import ToolMixin from "../../../mixins/accessors/tool";
import NftMixin from "../../../mixins/accessors/nft";
// Event Bus
import { EventBus } from "../../../event-bus/index";

import Axios from "../../../middleware/api";

@Component({
  components: { TopBar, Search, MiddleBar },
  filters: {
    fragment(address: any) {
      const frag_1: string = address.substr(0, 5);
      const frag_2: string = address.slice(-4);

      return `${frag_1}...${frag_2}`;
    },
  },
})
export default class BottomBar extends Mixins(NftMixin, ToolMixin) {
  name: string = "Top NFTs by Market Capitalization";
  enableSearch: boolean = false;

  get title() {
    return this.name;
  }

  private unlockWallet(): void {
    EventBus.$emit("UNLOCK_WALLET");
  }

  private lockWallet(): void {
    this.$cookies.remove("wallet");
    this.unlocked = false;
    this.myCollection.splice(0, this.myCollection.length);
  }

  private close(): void {
    this.enableSearch = false;
  }
}
