import { Schema1 } from "../schemas/schema-1";

export class Sven extends Schema1 {
  constructor(
    assetPrice: string | number,
    name: string = "Artist Drop 0 - Sven",
    address: string = "0x7e7b4509e4ee79D27DDF969DeEd261D4754Ec395"
  ) {
    super(assetPrice, name, address);
    this.initialize();
  }

  private async initialize(): Promise<any> {
    const abi = await super.getAbi();

    super.getContract(abi);

    await super.totalSupply();

    await super.getPastEvents();

    await super.getPineapples();
  }
}
