var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-dialog',{attrs:{"max-width":"512"},model:{value:(_vm.imageDialog),callback:function ($$v) {_vm.imageDialog=$$v},expression:"imageDialog"}},[_c('v-card',[_c('v-img',{staticClass:"customHeight zIndex",attrs:{"aspect-ratio":6 / 9,"contain":"","src":_vm.imageUrl}})],1)],1),_c('h1',{staticClass:"text-left paddingTop h2Font",class:{
      'white--text': _vm.$vuetify.theme.dark,
      'black--text': !_vm.$vuetify.theme.dark,
      mobileFont: _vm.$vuetify.breakpoint.smAndDown,
    }},[_vm._t("title")],2),_c('h4',{staticClass:"font-weight-normal smallText",class:_vm.$vuetify.breakpoint.smAndDown ? 'text-center' : 'text-left'},[_c('span',{class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text'},[_vm._t("total")],2),_c('span',{staticClass:"ml-5 smallText",class:_vm.$vuetify.theme.dark ? 'white--text' : 'black--text'},[_vm._t("marketCap")],2)]),_c('v-data-table',{staticClass:"elevation-0 tableEnd",class:{ removeSticky: _vm.$vuetify.breakpoint.smAndDown },attrs:{"search":_vm.search,"calculate-widths":"","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"loading":_vm.loading,"loading-text":"Loading... Please wait","hide-default-footer":"","mobile-breakpoint":"0"},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"item.token_rank",fn:function(ref){
    var item = ref.item;
return [_vm._t("rank",null,{"item":item})]}},{key:"item.image_thumbnail_url",fn:function(ref){
    var item = ref.item;
return [_vm._t("thumbnail",null,{"item":item})]}},{key:"item.last_sale_usd",fn:function(ref){
    var item = ref.item;
return [_vm._t("lastSale",null,{"item":item})]}},{key:"item.bid",fn:function(ref){
    var item = ref.item;
return [_vm._t("bid",null,{"item":item})]}},{key:"item.ask",fn:function(ref){
    var item = ref.item;
return [_vm._t("ask",null,{"item":item})]}},{key:"item.max_supply",fn:function(ref){
    var item = ref.item;
return [_vm._t("maxSupply",null,{"item":item})]}},{key:"item.market_cap",fn:function(ref){
    var item = ref.item;
return [_vm._t("market",null,{"item":item})]}},{key:"item.action",fn:function(ref){
    var item = ref.item;
return [_vm._t("action",null,{"item":item})]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"text-right dataFooter py-3 px-2"},[_c('span',{staticClass:"grey--text"},[_vm._v(" Rows per page ")]),_c('v-menu',{staticClass:"menuIndex",attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"dark":"","text":"","color":"primary"}},on),[_vm._v(" "+_vm._s(_vm.itemsPerPage)+" "),_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1)]}}])},[_c('v-list-item-group',{attrs:{"color":"indigo"},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},[_c('v-list',_vm._l((_vm.itemsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateItemsPerPage(number)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(number)+" ")])],1)],1)}),1)],1)],1),_c('v-icon',{on:{"click":_vm.formerPage}},[_vm._v(" mdi-chevron-left ")]),_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.page)+" of "+_vm._s(_vm.numberOfPages)+" ")]),_c('v-icon',{on:{"click":_vm.nextPage}},[_vm._v(" mdi-chevron-right ")])],1)]},proxy:true}],null,true)})],1)}
var staticRenderFns = []

export { render, staticRenderFns }