









import { Component, Prop, Vue } from "vue-property-decorator";

import Navigator from "../components/navigator/index.vue";
import Footer from "../components/footer/index.vue";
// Importing Event Bus
import { EventBus } from "../event-bus/index";

@Component({
  components: {
    Footer,
    Navigator,
  },
})
export default class Overview extends Vue {}
