var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('h1',{staticClass:"text-left paddingTop h2Font",class:{
      'white--text': _vm.$vuetify.theme.dark,
      'black--text': !_vm.$vuetify.theme.dark,
      mobileFont: _vm.$vuetify.breakpoint.smAndDown,
    }},[_vm._v(" "+_vm._s(_vm.title)+" ")]),(_vm.items)?_c('v-data-table',{staticClass:"tableEnd",class:{ removeSticky: _vm.$vuetify.breakpoint.smAndDown },attrs:{"search":_vm.search,"calculate-widths":"","headers":_vm.headers,"items":_vm.items,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"loading":_vm.loading,"loading-text":"Loading... Please wait","hide-default-footer":"","mobile-breakpoint":"-10"},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"item.actions",fn:function(ref){
    var item = ref.item;
return [_vm._t("item",null,{"item":item})]}},{key:"item.pineapples",fn:function(ref){
    var item = ref.item;
return [(item.pineapples == undefined)?_c('v-progress-circular',{attrs:{"indeterminate":"","color":_vm._f("rotateColor")('grey')}}):_c('span',[_vm._v(" "+_vm._s(_vm._f("formatDecimal")(item.pineapples))+" ")])]}},{key:"footer",fn:function(){return [_c('div',{staticClass:"text-right dataFooter py-3 px-2"},[_c('span',{staticClass:"grey--text"},[_vm._v(" Rows per page ")]),_c('v-menu',{staticClass:"menuIndex",attrs:{"offset-x":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
    var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"ml-2",attrs:{"dark":"","text":"","color":"primary"}},on),[_vm._v(" "+_vm._s(_vm.itemsPerPage)+" "),_c('v-icon',[_vm._v(" mdi-chevron-down ")])],1)]}}],null,false,1421263725)},[_c('v-list-item-group',{attrs:{"color":"indigo"},model:{value:(_vm.item),callback:function ($$v) {_vm.item=$$v},expression:"item"}},[_c('v-list',_vm._l((_vm.itemsPerPageArray),function(number,index){return _c('v-list-item',{key:index,on:{"click":function($event){return _vm.updateItemsPerPage(number)}}},[_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" "+_vm._s(number)+" ")])],1)],1)}),1)],1)],1),_c('v-icon',{on:{"click":_vm.formerPage}},[_vm._v(" mdi-chevron-left ")]),_c('span',{staticClass:"grey--text"},[_vm._v(" "+_vm._s(_vm.page)+" of "+_vm._s(_vm.numberOfPages)+" ")]),_c('v-icon',{on:{"click":_vm.nextPage}},[_vm._v(" mdi-chevron-right ")])],1)]},proxy:true}],null,true)}):_c('v-data-table',{staticClass:"elevation-8",attrs:{"search":_vm.search,"calculate-widths":"","headers":_vm.headers,"items-per-page":_vm.itemsPerPage,"page":_vm.page,"loading":_vm.loading,"loading-text":"Loading... Please wait","hide-default-footer":"","mobile-breakpoint":"0"},on:{"update:itemsPerPage":function($event){_vm.itemsPerPage=$event},"update:items-per-page":function($event){_vm.itemsPerPage=$event}},scopedSlots:_vm._u([{key:"top",fn:function(){return [_c('v-text-field',{staticClass:"mx-4",attrs:{"label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})]},proxy:true},{key:"footer",fn:function(){return [_c('div',{staticClass:"text-right dataFooter py-3 px-2"},[_c('v-icon',{on:{"click":_vm.formerPage}},[_vm._v(" mdi-chevron-left ")]),_c('span',{staticClass:"grey--text"},[_vm._v(" Loading ")]),_c('v-icon',{on:{"click":_vm.nextPage}},[_vm._v(" mdi-chevron-right ")])],1)]},proxy:true}])})],1)}
var staticRenderFns = []

export { render, staticRenderFns }