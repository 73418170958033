import { opensea, etherscan, coingecko } from "./configuration";
import { EventBus } from "../event-bus/index";
import Web3 from "web3";

import store from "../store/index";

const infura = new Web3.providers.HttpProvider(
  "https://mainnet.infura.io/v3/e1a2634a66fc4f4c816926b47ae06164"
  // "https://eth-mainnet.alchemyapi.io/v2/MHKoTXWvg8_Bnp87K8YuMmlN6tKf_heP"
  // "http://127.0.0.1:8545"
);

const web3 = new Web3(infura);
const _apikey = "BAFZ2TXWE984PWSQDVMF6EKER3DK2H1G4E";

export default {
  async getCollections(payload: any, source: any): Promise<any> {
    let offset: number = payload;
    try {
      const collection: string = `collection=${
        (store.state as any).nft.collection
      }`;
      // `/api/v1/assets?order_direction=desc&offset=${offset}&limit=50&collection=decentraland-wearables`
      return await opensea.get(
        `/api/v1/assets?order_direction=desc&offset=${offset}&limit=50&${collection}`,
        {
          cancelToken: source.token,
        }
      );
    } catch (error) {
      offset = 0;
      this.errorRedirect(error);
      return Promise.reject(error);
    }
  },
  async getBid(ids: string, _offset: number): Promise<any> {
    try {
      // qscorpio.defaults.headers.common["X-Embarcadero-Session-Token"] =
      //   payload.user.token;
      // const token_id: any = `token_id=${id}`;
      const include_invalid: string = "include_invalid=false";
      const side: string = "side=0";
      const limit: string = "limit=50";
      const offset: string = `offset=${_offset}`;
      const order_by: string = "order_by=eth_price";
      const order_direction: string = "order_direction=desc";
      const collection: string = `collection_slug=${
        (store.state as any).nft.collection
      }`;
      return await opensea.get(
        `/wyvern/v1/orders?${ids}&${include_invalid}&${side}&${limit}&${offset}&${order_by}&${order_direction}&${collection}`
      );
    } catch (error) {
      this.errorRedirect(error);
      return Promise.reject(error);
    }
  },

  async getAbi(payload: any): Promise<any> {
    const module: string = `module=contract`;
    const action: string = `action=getabi`;
    const address: string = `address=${payload}`;
    const apikey: string = `apikey=${_apikey}`;
    try {
      return await etherscan.get(
        `/api?${module}&${action}&${address}&${apikey}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getPoolTransactions(payload: any, _offset: number): Promise<any> {
    const module: string = `module=account`;
    const action: string = `action=tokentx`;
    const startBlock: string = `startblock=0`;
    const endBlock: string = `endblock=999999999`;
    const offset: string = `offset=${_offset}`;
    const address: string = `address=${payload}`;
    const apikey: string = `apikey=${_apikey}`;
    try {
      return await etherscan.get(
        `/api?${module}&${action}&${address}&${startBlock}&${endBlock}&${offset}&${apikey}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getContract(payload: any): Promise<any> {
    try {
      return new web3.eth.Contract(JSON.parse(payload.abi), payload.contract);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getMemePrice(): Promise<any> {
    try {
      return await coingecko.get(`/coins/weth`);
    } catch (error) {
      return Promise.reject(error);
    }
  },

  async getMyCollection(_offset: number, walletAddress: string): Promise<any> {
    const assetContract: string = `asset_contract=0xe4605d46fd0b3f8329d936a8b258d69276cba264`;
    const format: string = `format=json`;
    const limit: string = `limit=20`;
    const offset: string = `offset=${_offset}`;
    const orderBy: string = `order_by=token_id`;
    const orderDirection: string = `order_direction=asc`;
    const owner: string = `owner=${walletAddress}`;
    const collection: string = "collection_slug=meme-ltd";
    try {
      return await opensea.get(
        `/api/v1/assets/?${assetContract}&${format}&${limit}&${offset}&${orderBy}&${orderDirection}&${owner}&${collection}`
      );
    } catch (error) {
      return Promise.reject(error);
    }
  },

  errorRedirect(error: any): void {
    const status: any = error.response.status;

    if (status == "401") {
      EventBus.$emit("401_REDIRECT");
    }
  },
};
