















































































































































import moment from "moment";
// Mixins
import ToolMixin from "../../mixins/accessors/tool";
// Vue property decorator
import { Component, Prop, Emit, Vue, Mixins } from "vue-property-decorator";

@Component({
  components: {},
})
export default class Footer extends Mixins(ToolMixin) {
  currentYear: any = 2020;
  theme: boolean = this.$vuetify.theme.dark;
}
