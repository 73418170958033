var render, staticRenderFns
import script from "./index.vue?vue&type=script&lang=ts&"
export * from "./index.vue?vue&type=script&lang=ts&"
import style0 from "./index.vue?vue&type=style&index=0&id=0c6f8780&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "0c6f8780",
  null
  
)

/* custom blocks */
import block0 from "./index.vue?vue&type=custom&index=0&blockType=v-autocomplete&v-model=model&%3Aitems=items&%3Aloading=isLoading&%3Asearch-input.sync=search&%3Alabel=%24vuetify.breakpoint.smAndDown%20%3F%20null%20%3A%20'Search'&clearable=true&hide-details=true&hide-selected=true&item-text=name&item-value=symbol&%3Aappend-outer-icon=%24vuetify.breakpoint.smAndDown%20%3F%20'mdi-close'%20%3A%20''&%40click%3Aappend-outer=closeSearch()&%3Asolo=%24vuetify.breakpoint.smAndDown%20%3F%20false%20%3A%20true"
if (typeof block0 === 'function') block0(component)

export default component.exports