




























import { Component, Prop, Mixins, Watch } from "vue-property-decorator";
import Vue from "vue";
// Importing Event Bus
import { EventBus } from "../../../event-bus/index";
// Mixins
import ToolMixin from "../../../mixins/accessors/tool";

@Component({
  components: {},
})
export default class ContentProgress extends Mixins(ToolMixin) {}
